<template>
  <div>
    <form class="step" @submit.prevent="submit">
      <ValidationObserver ref="step2" v-slot="{ invalid }">
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div class="d-inline-flex align-items-center">
                <label for="capacity">
                  <span v-html="$t('capacity')"></span>
                  <span
                    v-tooltip="$t('tooltips.capacity')"
                    class="tooltip-info ml-2"
                  >
                    <font-awesome-icon icon="info" class="" />
                  </span>
                </label>
              </div>
              <span v-html="$t('capacityExample')"></span>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="input-group mt-3">
                  <input
                    id="capacity"
                    ref="capacity"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="capacity"
                  />
                  <div class="input-group-append">
                    <select
                      id="capacityUnit"
                      class="custom-select"
                      :value="capacityUnit"
                      @input="inputValueChange($event, 'capacityUnit')"
                    >
                      <option
                        value="horsePower"
                        v-html="$t('unit.horsePower')"
                      ></option>
                      <option
                        value="steamPower"
                        v-html="$t('unit.steamPower')"
                      ></option>
                    </select>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="operationDays" v-html="$t('operationDays')"></label>
              <span v-html="$t('operationDaysExample')"></span>
              <ValidationProvider
                :rules="{ min_value: 0, max_value: 365, required: true }"
                v-slot="{ errors }"
              >
                <div class="input-group mt-3">
                  <input
                    id="operationDays"
                    step="1"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="operationDays"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      v-html="$t('unit.days')"
                    ></span>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="operationHours" v-html="$t('operationHours')"></label>
              <span v-html="$t('operationHoursExample')"></span>
              <ValidationProvider
                :rules="{ required: true, min_value: 0, max_value: 24 }"
                v-slot="{ errors }"
              >
                <div class="input-group mt-3">
                  <input
                    id="operationHours"
                    step="1"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="operationHours"
                  />
                  <div class="input-group-append">
                    <span
                      class="input-group-text"
                      v-html="$t('unit.hours')"
                    ></span>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div class="d-inline-flex align-items-center">
                <label for="capacity">
                  <span v-html="$t('makeupWater')"></span>
                  <span
                    v-tooltip="$t('tooltips.makeupWater')"
                    class="tooltip-info ml-2"
                  >
                    <font-awesome-icon icon="info" class="" />
                  </span>
                </label>
              </div>
              <span v-html="$t('makeupWaterExample')"></span>
              <ValidationProvider
                :rules="{ required: true, min_value: 0, max_value: 100 }"
                v-slot="{ errors }"
              >
                <div class="input-group mt-3">
                  <input
                    id="makeupWater"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="makeupWater"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <div class="d-inline-flex align-items-center">
                <label for="capacity">
                  <span v-html="$t('efficiency')"></span>
                  <span
                    v-tooltip="$t('tooltips.efficiency')"
                    class="tooltip-info ml-2"
                  >
                    <font-awesome-icon icon="info" class="" />
                  </span>
                </label>
              </div>
              <span v-html="$t('efficiencyExample')"></span>
              <ValidationProvider
                :rules="{ required: true, min_value: 0, max_value: 100 }"
                v-slot="{ errors }"
              >
                <div class="input-group mt-3">
                  <input
                    id="efficiency"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="efficiency"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <label
                for="averageCapacity"
                v-html="$t('averageCapacity')"
              ></label>
              <span v-html="$t('averageCapacityExample')"></span>
              <ValidationProvider
                :rules="{ required: true, min_value: 0, max_value: 100 }"
                v-slot="{ errors }"
              >
                <div class="input-group mt-3">
                  <input
                    id="averageCapacity"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="averageCapacity"
                  />
                  <div class="input-group-append">
                    <span class="input-group-text">%</span>
                  </div>
                </div>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <ActionButtons
          :hasNext="true"
          :hasPrevious="true"
          :previousStep="previousStep"
        />
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import ActionButtons from "@/components/calculator/ActionButtons.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Step2",
  components: {
    ActionButtons,
    ValidationObserver,
    ValidationProvider
  },
  props: ["capacityUnit", "previousStep", "nextStep"],
  data() {
    return {
      capacity: this.$store.getters.capacity,
      operationDays: this.$store.getters.operationDays,
      operationHours: this.$store.getters.operationHours,
      makeupWater: this.$store.getters.makeupWater,
      efficiency: this.$store.getters.efficiency,
      averageCapacity: this.$store.getters.averageCapacity
    };
  },
  mounted() {
    this.$refs.capacity.focus();
  },
  methods: {
    inputValueChange(event, key) {
      this.$emit(`update:${key}`, event.target.value);
    },
    async submit() {
      const isValid = await this.$refs.step2.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("setCapacity", this.capacity);
      this.$store.commit("setOperationDays", this.operationDays);
      this.$store.commit("setOperationHours", this.operationHours);
      this.$store.commit("setMakeupWater", this.makeupWater);
      this.$store.commit("setEfficiency", this.efficiency);
      this.$store.commit("setAverageCapacity", this.averageCapacity);
      this.nextStep();
    }
  }
};
</script>

<style lang="scss" scoped>
.is-danger {
  border: 1px solid red !important;
}
</style>
