<template>
  <div class="row mb-4">
    <div class="col">
      <div class="progress">
        <div
          class="bg-info progress-bar"
          role="progressbar"
          :style="{ width: currentProgress + '%' }"
          :aria-valuenow="currentProgress"
          aria-valuemin="0"
          aria-valuemax="100"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: [ "step", "totalSteps" ],
  computed: {
    currentProgress() {
      return (this.step / (this.totalSteps + 1)) * 100;
    }
  }
};
</script>

<style lang="scss" scoped>
.progress {
  height: 10px;
  border: 1px solid #0d77a9;
  background-color: transparent;
}
</style>
