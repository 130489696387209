import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    stepIndex: 0,

    areCookiesEnabled: false,
    country: "other",
    region: null,

    // Step 1:
    name: localStorage.getItem("name"),
    cie: localStorage.getItem("cie"),
    email: localStorage.getItem("email"),
    phone: localStorage.getItem("phone"),

    // Step 2:
    capacity: localStorage.getItem("capacity"),
    operationDays: localStorage.getItem("operationDays"),
    operationHours: localStorage.getItem("operationHours"),
    makeupWater: localStorage.getItem("makeupWater"),
    efficiency: localStorage.getItem("efficiency"),
    averageCapacity: localStorage.getItem("averageCapacity"),

    // Step 3:
    gasPrice: localStorage.getItem("gasPrice"),
    waterCost: localStorage.getItem("waterCost"),
    operatorWage: localStorage.getItem("operatorWage"),
    co2Cost: localStorage.getItem("co2Cost"),

    // Step 4:
    conventionalProductsQuantity: localStorage.getItem(
      "conventionalProductsQuantity"
    ),
    sulfitePhosphateProportions: localStorage.getItem(
      "sulfitePhosphateProportions"
    ),
    pumps: localStorage.getItem("pumps"),
    preparationControlTime: localStorage.getItem("preparationControlTime"),
    alkalinity: localStorage.getItem("alkalinity"),
    silica: localStorage.getItem("silica"),

    // Step 5:
    conductivity: localStorage.getItem("conductivity"),
    maintainedConductivity: localStorage.getItem("maintainedConductivity"),
    inventoryCost: localStorage.getItem("inventoryCost"),
    maintenanceCost: localStorage.getItem("maintenanceCost")
  },
  mutations: {
    setStepIndex(state, index) {
      if (index > state.stepIndex) {
        state.stepIndex = index;
        // console.log("Step: " + index.toString());
        window.dataLayer.push({
          event: "Calculatrice",
          step: index.toString()
        });
      }
    },

    setCookiesEnabled(state, areCookiesEnabled) {
      state.areCookiesEnabled = areCookiesEnabled;
    },
    setCountry(state, country) {
      state.country = country;
    },
    setRegion(state, region) {
      state.region = region;
    },

    // Step 1:
    setName(state, name) {
      state.name = name;
      localStorage.setItem("name", name);
    },
    setCie(state, cie) {
      state.cie = cie;
      localStorage.setItem("cie", cie);
    },
    setEmail(state, email) {
      state.email = email;
      localStorage.setItem("email", email);
    },
    setPhone(state, phone) {
      state.phone = phone;
      localStorage.setItem("phone", phone);
    },

    // Step 2:
    setCapacity(state, capacity) {
      state.capacity = capacity;
      localStorage.setItem("capacity", capacity);
    },
    setOperationDays(state, operationDays) {
      state.operationDays = operationDays;
      localStorage.setItem("operationDays", operationDays);
    },
    setOperationHours(state, operationHours) {
      state.operationHours = operationHours;
      localStorage.setItem("operationHours", operationHours);
    },
    setMakeupWater(state, makeupWater) {
      state.makeupWater = makeupWater;
      localStorage.setItem("makeupWater", makeupWater);
    },
    setEfficiency(state, efficiency) {
      state.efficiency = efficiency;
      localStorage.setItem("efficiency", efficiency);
    },
    setAverageCapacity(state, averageCapacity) {
      state.averageCapacity = averageCapacity;
      localStorage.setItem("averageCapacity", averageCapacity);
    },

    // Step 3:
    setGasPrice(state, gasPrice) {
      state.gasPrice = gasPrice;
      localStorage.setItem("gasPrice", gasPrice);
    },
    setWaterCost(state, waterCost) {
      state.waterCost = waterCost;
      localStorage.setItem("waterCost", waterCost);
    },
    setOperatorWage(state, operatorWage) {
      state.operatorWage = operatorWage;
      localStorage.setItem("operatorWage", operatorWage);
    },
    setCo2Cost(state, co2Cost) {
      state.co2Cost = co2Cost;
      localStorage.setItem("co2Cost", co2Cost);
    },

    // Step 4:
    setConventionalProductsQuantity(state, conventionalProductsQuantity) {
      state.conventionalProductsQuantity = conventionalProductsQuantity;
      localStorage.setItem(
        "conventionalProductsQuantity",
        conventionalProductsQuantity
      );
    },
    setSulfitePhosphateProportions(state, sulfitePhosphateProportions) {
      state.sulfitePhosphateProportions = sulfitePhosphateProportions;
      localStorage.setItem(
        "sulfitePhosphateProportions",
        sulfitePhosphateProportions
      );
    },
    setPumps(state, pumps) {
      state.pumps = pumps;
      localStorage.setItem("pumps", pumps);
    },
    setPreparationControlTime(state, preparationControlTime) {
      state.preparationControlTime = preparationControlTime;
      localStorage.setItem("preparationControlTime", preparationControlTime);
    },
    setAlkalinity(state, alkalinity) {
      state.alkalinity = alkalinity;
      localStorage.setItem("alkalinity", alkalinity);
    },
    setSilicia(state, silica) {
      state.silica = silica;
      localStorage.setItem("silica", silica);
    },

    // Step 5:
    setConductivity(state, conductivity) {
      state.conductivity = conductivity;
      localStorage.setItem("conductivity", conductivity);
    },
    setMaintainedConductivity(state, maintainedConductivity) {
      state.maintainedConductivity = maintainedConductivity;
      localStorage.setItem("maintainedConductivity", maintainedConductivity);
    },
    setInventoryCost(state, inventoryCost) {
      state.inventoryCost = inventoryCost;
      localStorage.setItem("inventoryCost", inventoryCost);
    },
    setMaintenanceCost(state, maintenanceCost) {
      state.maintenanceCost = maintenanceCost;
      localStorage.setItem("maintenanceCost", maintenanceCost);
    }
  },
  getters: {
    areCookiesEnabled: state => state.areCookiesEnabled,
    country: state => state.country,
    region: state => state.region,

    // Step 1:
    name: state => state.name,
    cie: state => state.cie,
    email: state => state.email,
    phone: state => state.phone,

    // Step 2:
    capacity: state => state.capacity,
    operationDays: state => state.operationDays,
    operationHours: state => state.operationHours,
    makeupWater: state => state.makeupWater,
    efficiency: state => state.efficiency,
    averageCapacity: state => state.averageCapacity,

    // Step 3:
    gasPrice: state => state.gasPrice,
    waterCost: state => state.waterCost,
    operatorWage: state => state.operatorWage,
    co2Cost: state => state.co2Cost,

    // Step 4:
    conventionalProductsQuantity: state => state.conventionalProductsQuantity,
    sulfitePhosphateProportions: state => state.sulfitePhosphateProportions,
    pumps: state => state.pumps,
    preparationControlTime: state => state.preparationControlTime,
    alkalinity: state => state.alkalinity,
    silica: state => state.silica,

    // Step 5:
    conductivity: state => state.conductivity,
    maintainedConductivity: state => state.maintainedConductivity,
    inventoryCost: state => state.inventoryCost,
    maintenanceCost: state => state.maintenanceCost
  }
});
