<template>
  <div class="container">
    <div class="row my-5" v-for="(question, index) in questions" :key="index">
      <div class="col">
        <Question :index="index + 1" />
      </div>
    </div>
  </div>
</template>

<script>
import Question from "@/components/Question.vue";

export default {
  name: "Faq",
  components: {
    Question
  },
  data() {
    return {
      questions: Object.values(this.$i18n.messages[this.$i18n.locale].questions)
    };
  }
};
</script>
