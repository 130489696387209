/*
 * CONVERSION
 */
export const STEAM_POWER_TO_HORSE_POWER = 34.5;
export const CUBIC_FEET_TO_CUBIC_METER = 0.02832;
export const US_GALLONS_TO_CUBIC_METER = 0.00378541;
export const CUBIC_FEET_TO_BTU = 1000;
export const LBS_TO_BTU = 236;
export const LBS_TO_KG = 0.4536;
export const CUBIC_METER_OF_GAS_TO_CO2 = 2.3;
export const TON_TO_SHORT_TON = 1.1023113109244;
export const SHORT_TON_TO_TON = 0.907185;
export const CUBIC_METER_TO_GAL = 264.172;
export const CUBIC_METER_TO_CUBIC_FEET = 35.31467;

/*
 * CONSTANTS
 */
export const MAX_AQUAFILM_CYCLE_VALUE = 120;
export const MAX_BOILER_ALKALINITY = 700;
export const MAX_BOILER_SILICA = 150;
export const REQUIRED_AQUAFILM_DOSAGE = 800;
export const ENERGY_TRANSFER_GAIN = 0.005;
export const AQUAFILM_BOILER_CONDUCTIVITY = 7000;

/*
 * UNITS
 */
export const CUBIC_METER_ENERGY = "cubicMeterEnergy";
export const CUBIC_FEET_ENERGY = "cubicFeetEnergy";
export const WATER_LITERS = "waterLiters";
export const WATER_GALLONS = "waterGallons";
export const WATER_CUBIC_METERS = "waterCubicMeters";
export const CO2_METRIC_TONS = "co2MetricTons";
export const CO2_SHORT_TONS = "co2ShortTons";
export const DOLLARS_PER_CUBIC_METER = "dollarsPerCubicMeter";
export const DOLLARS_PER_TON = "dollarsPerTon";
export const CUBIC_METERS = "cubicMeters";
export const LITERS = "liters";
export const TONS = "tons";
export const SHORT_TONS = "shortTons";
export const KILOGRAM = "kg";
export const KILOGRAM_BY_YEAR = "kgPerYear";
export const LBS = "lbs";
export const HORSE_POWER = "horsePower";
