import DistributorMixin from "@/mixins/DistributorMixin.js";

import { HTTP } from "@/utils/axios.js";

export default {
  mixins: [DistributorMixin],
  methods: {
    getData(messages, results) {
      const country = this.$store.getters.country;
      const region = this.$store.getters.region;
      const name = this.getDistributorLabel(country, region);

      const data = {
        distributor: {
          name: name,
          country: this.$store.getters.country,
          region: this.$store.getters.region
        },
        labels: {
          nameLabel: messages.name,
          emailLabel: messages.email,
          phoneLabel: messages.phone,
          cieLabel: messages.cie,
          capacityLabel: messages.capacity,
          operationDaysLabel: messages.operationDays,
          operationHoursLabel: messages.operationHours,
          makeupWaterLabel: messages.makeupWater,
          efficiencyLabel: messages.efficiency,
          averageCapacityLabel: messages.averageCapacity,
          gasPriceLabel: messages.gasPrice,
          waterCostLabel: messages.waterCost,
          operatorWageLabel: messages.operatorWage,
          co2CostLabel: messages.co2Cost,
          conventionalProductsQtyLabel: messages.conventionalProductsQuantity,
          sulfitePhosphateProportionsLabel:
            messages.sulfitePhosphateProportions,
          pumpsLabel: messages.pumps,
          preparationControlTimeLabel: messages.preparationControlTime,
          alkalinityLabel: messages.alkalinity,
          silicaLabel: messages.silica,
          conductivityLabel: messages.conductivity,
          maintainedConductivityLabel: messages.maintainedConductivity,
          inventoryCostLabel: messages.inventoryCost,
          maintenanceCostLabel: messages.maintenanceCost,
          energySavingLabel: messages.results.energy,
          waterSavingLabel: messages.results.water,
          co2SavingLabel: messages.results.co2,
          totalSavingLabel: messages.results.total,
          energyLostReductionLabel: messages.results.energyLostReduction,
          energyTransferSavingLabel: messages.results.energyTransferSaving,
          co2CostSavingLabel: messages.results.co2CostSaving,
          waterCostSavingLabel: messages.results.waterCostSaving,
          inventoryCostSavingLabel: messages.results.inventoryCostSaving,
          preparationControlTimeCostSavingLabel:
            messages.results.controlTimePerWeek,
          maintenanceCostSavingLabel: messages.results.maintenanceCostSaving,
          concentrationCyclesLabel: messages.results.concentrationCycles,
          controlTimePerWeekLabel: messages.results.controlTimePerWeek,
          energyTransferOptimizationLabel:
            messages.results.energyTransferOptimisation,
          productsQuantitySavingLabel: messages.results.productsQuantitySaving
        },
        data: {
          name: this.$store.getters.name,
          cie: this.$store.getters.cie,
          email: this.$store.getters.email,
          phone: this.$store.getters.phone,
          capacity: this.$store.getters.capacity,
          capacityUnit: messages.unit[this.capacityUnit],
          operationDays: this.$store.getters.operationDays,
          operationDaysUnit: messages.unit.days,
          operationHours: this.$store.getters.operationHours,
          operationHoursUnit: messages.unit.hours,
          makeupWater: this.$store.getters.makeupWater,
          makeupWaterUnit: messages.unit.percentage,
          efficiency: this.$store.getters.efficiency,
          efficiencyUnit: messages.unit.percentage,
          averageCapacity: this.$store.getters.averageCapacity,
          averageCapacityUnit: messages.unit.percentage,
          gasPrice: this.$store.getters.gasPrice,
          gasPriceUnit: messages.unit[this.gasPriceUnit],
          waterCost: this.$store.getters.waterCost,
          waterCostUnit: messages.unit.dollar,
          operatorWage: this.$store.getters.operatorWage,
          operatorWageUnit: messages.unit.dollar,
          co2Cost: this.$store.getters.co2Cost,
          co2CostUnit: messages.unit[this.co2CostUnit],
          conventionalProductsQuantity: this.$store.getters
            .conventionalProductsQuantity,
          conventionalProductsQuantityUnit:
            messages.unit[this.productsQuantityUnit],
          sulfitePhosphateProportions: this.$store.getters
            .sulfitePhosphateProportions,
          sulfitePhosphateProportionsUnit:
            messages.unit[this.sulfitePhosphateProportionsUnit],
          pumps: this.$store.getters.pumps,
          preparationControlTime: this.$store.getters.preparationControlTime,
          preparationControlTimeUnit: messages.unit.hours,
          alkalinity: this.$store.getters.alkalinity,
          alkalinityUnit: messages.unit.ppm,
          silica: this.$store.getters.silica,
          silicaUnit: messages.unit.ppm,
          conductivity: this.$store.getters.conductivity,
          conductivityUnit: messages.unit.conductivity,
          maintainedConductivity: this.$store.getters.maintainedConductivity,
          maintainedConductivityUnit: messages.unit.conductivity,
          inventoryCost: this.$store.getters.inventoryCost,
          inventoryCostUnit: messages.unit.dollar,
          maintenanceCost: this.$store.getters.maintenanceCost,
          maintenanceCostUnit: messages.unit.dollar
        },
        results: {
          energySaving: results.energySaving,
          energySavingUnit: messages.unit[results.energySavingUnit],
          waterSaving: results.waterSaving,
          waterSavingUnit: messages.unit[results.waterSavingUnit],
          co2Saving: results.co2Saving,
          co2SavingUnit: messages.unit[results.co2SavingUnit],
          totalSaving: results.totalSaving,
          totalSavingUnit: messages.unit.dollar,
          energyLostReduction: this.getGasCostSaving(),
          energyLostReductionUnit: messages.unit.dollar,
          energyTransferSaving: this.getEnergyTransferSaving(),
          energyTransferSavingUnit: messages.unit.dollar,
          co2CostSaving: this.getCO2CostSaving(),
          co2CostSavingUnit: messages.unit.dollar,
          waterCostSaving: this.getWaterCostSaving(),
          waterCostSavingUnit: messages.unit.dollar,
          inventoryCostSaving: this.getInventoryCostSaving(),
          inventoryCostSavingUnit: messages.unit.dollar,
          preparationControlTimeCostSaving: this.getReductionOfMonitoringTime(),
          preparationControlTimeCostSavingUnit: messages.unit.dollar,
          maintenanceCostSaving: this.getMaintenanceCostSaving(),
          maintenanceCostSavingUnit: messages.unit.dollar,
          actualConcentrationCycle: this.getEstimatedTheoreticalPowerCycle(),
          aquafilmConcentrationCycle: this.getWeightedAquafilmCycleValue(),
          energyTransferOptimization: 0.5,
          energyTransferOptimizationUnit: messages.unit.percentage,
          preparationControlTimeReductionPerWeek: results.controlTimePerWeek,
          preparationControlTimeReductionPerWeekUnit: messages.unit.dollar,
          productsQuantitySaving: results.productsQuantitySaving,
          productsQuantitySavingUnit:
            messages.unit[results.productsQuantitySavingUnit]
        }
      };

      return data;
    },
    sendResultsToMagnus(results) {
      const messages = this.$i18n.messages["en"];
      const data = this.getData(messages, results);

      console.log("--- Send data to Magnus ---");
      console.log(data);
      return HTTP.post("calculator", {
        data: data
      });
    },
    sendResultsToEmail(email, locale, results) {
      const messages = this.$i18n.messages[locale];
      const data = this.getData(messages, results);

      (data.send = {
        email: email,
        locale: locale
      }),
      console.log("--- Send data to " + email + " ---");
      console.log(data);
      return HTTP.post("calculator/send", {
        data: data
      });
    }
  }
};
