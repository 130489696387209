<template>
  <div id="navigaion">
    <div class="navigation">
      <div class="navbar container">
        <div class="row align-items-center justify-content-between w-100">
          <div
            class="col-lg-6 text-center text-lg-left mt-2 mb-n2 mt-lg-0 mb-lg-0"
          >
            <div class="d-inline-flex align-items-center">
              <a href="" class="brand-logo" @click.prevent="goToHomePage">
                <img src="@/assets/aquafilm.svg" />
              </a>
              <div
                class="d-flex align-items-center"
                v-if="$route.params.distributor"
              >
                <div class="vertical-line mx-3"></div>
                <div v-bind:class="getBrandLogoClass">
                  <img
                    :src="
                      require(`@/assets/distributors/${
                        $route.params.distributor
                      }.png`)
                    "
                  />
                </div>
              </div>
              <LanguageSwitcher
                :isBlue="false"
                class="d-inline d-lg-none ml-4 border-0"
              />
            </div>
          </div>

          <!-- Links -->
          <div class="col-lg-auto text-center">
            <div class="square-container">
              <div class="square square--small d-none d-lg-block"></div>
              <div class="square-links">
                <span>
                  <a href="" @click.prevent="changeRegion">
                    <span
                      class="text-nowrap"
                      v-html="$t('changeRegionLink')"
                    ></span>
                  </a>
                  <span class="mx-2">|</span>
                  <a
                    class="position-relative"
                    href="#expert"
                    v-if="
                      $route.name === 'Home' || $route.name === 'Distributor'
                    "
                    v-smooth-scroll="{ offset: -165 }"
                  >
                    <span v-html="$t('contactLink')"></span>
                  </a>
                  <span v-else>
                    <a
                      href=""
                      @click.prevent="goToHomePage"
                      class="text-white mr-4"
                    >
                      <img
                        src="@/assets/icons/back.svg"
                        class="back-icon mb-1 mr-1"
                      />
                      <span v-html="$t('studyCase.back')"></span>
                    </a>
                  </span>
                </span>
              </div>
            </div>
            <span class="ml-3 ml-lg-5 d-none d-lg-inline">
              <LanguageSwitcher :isBlue="false" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";
import DistributorMixin from "@/mixins/DistributorMixin.js";

export default {
  name: "Navbar",
  mixins: [DistributorMixin],
  components: {
    LanguageSwitcher
  },
  data() {
    return {
      isPageScrolled: false
    };
  },
  computed: {
    getBrandLogoClass() {
      const isBiggerBrandLogo =
        this.$route.params.distributor === "premierwater" ||
        this.$route.params.distributor === "pecowater";
      return isBiggerBrandLogo ? "bigger-brand-logo" : "brand-logo";
    }
  },
  methods: {
    goToHomePage() {
      const name =
        this.$route.name === "DistributorStudyCase" ? "Distributor" : "Home";
      this.$router.push({ name: name });
    },
    handleScroll() {
      this.isPageScrolled = window.pageYOffset > 20;
    },
    changeRegion() {
      this.$root.$emit("openRegionSelectorModal", true);
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>
<style lang="scss" scoped>
.navigation {
  z-index: $dropdown-zindex;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  transition: height 0.2s;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
}

.navbar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 115px;
  width: 100%;
}

.container-fluid,
.row {
  height: 100%;
}

.back-icon {
  height: 25px;
  width: auto;
}

.square-container {
  position: relative;
  display: inline-flex;
  top: -20px;
  width: 290px;

  .square {
    position: absolute;
    top: -10px;
    left: 0px;
    width: 100%;
    pointer-events: none;
    background-color: $primary;
    opacity: 0.8;
    transition-property: all;
    transition-timing-function: linear;
    transition-duration: 0.5s;
    z-index: 1;
  }

  .square--large {
    height: 290px;
  }

  .square--small {
    height: 83px;
  }

  .square-links {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    color: white;
    z-index: 2;

    @media (max-width: $large_screen_width) {
      color: $dark-grey;
    }

    a {
      color: inherit;
    }
  }
}
</style>
