<template>
  <div class="bg-light-grey">
    <div class="container">
      <div class="row mb-4">
        <div class="col">
          <h3 class="text-secondary text-center" v-html="$t('map.title')"></h3>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div id="map-section">&nbsp;</div>
          <div ref="gmaps" class="map"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* global google */

import distributors from "../distributors.json";
import styles from "../googleMapsStyles.json";

export default {
  name: "Map",
  data() {
    return {
      mapState: window.mapState
    };
  },
  mounted() {
    // map is already ready
    if (this.mapState.initMap) {
      this.loadMap();
    }
    this.$root.$on("distributor", () => {
      this.loadMap();
    });
  },
  watch: {
    // we watch the state for changes in case the map was not ready when this
    // component is first rendered
    // the watch will trigger when `initMap` will turn from `false` to `true`
    "mapState.initMap"(isLoaded) {
      if (isLoaded) {
        this.loadMap();
      }
    }
  },
  methods: {
    loadMap() {
      const self = this;

      try {
        const icon = {
          url: require("@/assets/marker.svg"),
          scaledSize: { width: 50, height: 50 }
        };
        const markers = [];
        for (let i = 0; i < distributors.length; i++) {
          const marker = distributors[i];

          marker.position = new window.google.maps.LatLng(
            distributors[i].pos.lat,
            distributors[i].pos.lng
          );
          marker.image = require(`@/assets/distributors/${marker.img}`);
          marker.icon = icon;
          markers.push(marker);
        }
        const options = {
          styles,
          zoom: 2,
          center: new window.google.maps.LatLng(45.5, -98.5821872),
          streetViewControl: false
        };
        const map = new window.google.maps.Map(self.$refs.gmaps, options);
        const infoWindow = new window.google.maps.InfoWindow();
        // const addresses = this.getDistributorAddresses(markers);

        markers.map(x => {
          const marker = new window.google.maps.Marker({ ...x, map });
          marker.addListener("click", function() {
            infoWindow.setContent(self.getInfoWindowContent(x));
            infoWindow.open(map, marker);
          });
        });
      } catch (error) {
        console.error(error);
      }
    },
    getDistributorAddresses(markers) {
      const self = this;

      if (!self.$route.params.distributor) {
        return markers;
      }

      return markers.filter(
        marker => marker.name === self.$route.params.distributor
      );
    },
    getFormattedAddress(position) {
      let address = `${position.city}, ${position.region}, ${position.postalCode}`;

      if (position.country) {
        address += `, ${position.country}`;
      }

      return address;
    },
    getInfoWindowContent(marker) {
      let content = `
        <div class="info-window-card">
          <img class="info-window-image mb-2" src="${marker.image}" />
          <div>${marker.address}</div>
          <div>${this.getFormattedAddress(marker)}</div>
      `;
      if (marker.phone) {
        content += `<a target="_blank" href="tel:${marker.phone}">${marker.phone}</a></div>`;
      }
      content += `<a target="_blank" href=${marker.website}>${marker.website}</a></div>`;
      return content;
    }
  }
};
</script>

<style lang="scss">
  .info-window-card {
    font-family: "arial_rounded_mt_stdregular", sans-serif;
  }

  .info-window-image {
    max-height: 40px;
    max-width: 100px !important;
  }

  .map {
    position: relative;
    width: 100%;
    height: 475px;
  }

  #map-section {
    position: absolute;
    top: calc(-1 * var(--top-offset));
  }
</style>
