<template>
  <form class="step" @submit.prevent="submit">
    <ValidationObserver ref="step5" v-slot="{ invalid }">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="conductivity">
                <span v-html="$t('conductivity')"></span>
                <span
                  v-tooltip="$t('tooltips.conductivity')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('conductivityExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: alkalinity }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="conductivity"
                  ref="conductivity"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="conductivity"
                />
                <div class="input-group-append">
                  <span class="input-group-text">μmhos</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="maintainedConductivity">
                <span v-html="$t('maintainedConductivity')"></span>
                <span
                  v-tooltip="$t('tooltips.maintainedConductivity')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('maintainedConductivityExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: silica }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="maintainedConductivity"
                  step="any"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="maintainedConductivity"
                />
                <div class="input-group-append">
                  <span class="input-group-text">μmhos</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="inventoryCost" v-html="$t('inventoryCost')"></label>
            <span v-html="$t('inventoryCostExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="inventoryCost"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="inventoryCost"
                />
                <div class="input-group-append">
                  <span class="input-group-text">$</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="capacity">
                <span v-html="$t('maintenanceCost')"></span>
                <span
                  v-tooltip="$t('tooltips.maintenanceCost')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('maintenanceCostExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="maintenanceCost"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="maintenanceCost"
                />
                <div class="input-group-append">
                  <span class="input-group-text">$</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <ActionButtons
        :hasNext="true"
        :hasPrevious="true"
        :previousStep="previousStep"
      />
    </ValidationObserver>
  </form>
</template>

<script>
import ActionButtons from "@/components/calculator/ActionButtons.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Step5",
  components: {
    ActionButtons,
    ValidationObserver,
    ValidationProvider
  },
  props: ["previousStep", "nextStep"],
  data() {
    return {
      conductivity: this.$store.getters.conductivity,
      maintainedConductivity: this.$store.getters.maintainedConductivity,
      inventoryCost: this.$store.getters.inventoryCost,
      maintenanceCost: this.$store.getters.maintenanceCost,
      alkalinity: this.$store.getters.alkalinity,
      silica: this.$store.getters.silica
    };
  },
  mounted() {
    this.$refs.conductivity.focus();
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.step5.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("setConductivity", this.conductivity);
      this.$store.commit(
        "setMaintainedConductivity",
        this.maintainedConductivity
      );
      this.$store.commit("setInventoryCost", this.inventoryCost);
      this.$store.commit("setMaintenanceCost", this.maintenanceCost);
      this.nextStep();
    }
  }
};
</script>

<style lang="scss" scoped>
.is-danger {
  border: 1px solid red !important;
}
</style>
