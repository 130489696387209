<template>
  <form class="step" @submit.prevent="submit">
    <ValidationObserver ref="step3" v-slot="{ invalid }">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="capacity">
                <span v-html="$t('gasPrice')"></span>
                <span
                  v-tooltip="$t('tooltips.gasPrice')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('gasPriceExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="gasPrice"
                  ref="gasPrice"
                  step="any"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="gasPrice"
                />
                <div class="input-group-append">
                  <select
                    class="custom-select"
                    id="gasPriceUnit"
                    :value="gasPriceUnit"
                    @change="inputValueChange($event, 'gasPriceUnit')"
                  >
                    <option
                      value="dollarsPerCubicMeter"
                      v-html="$t('unit.dollarsPerCubicMeter')"
                    ></option>
                    <option
                      value="dollarsPerCubicFeet"
                      v-html="$t('unit.dollarsPerCubicFeet')"
                    ></option>
                  </select>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="waterCost" v-html="$t('waterCost')"></label>
            <span v-html="$t('waterCostExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="waterCost"
                  v-validate
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="waterCost"
                />
                <div class="input-group-append">
                  <select
                    class="custom-select"
                    id="waterCostUnit"
                    :value="waterCostUnit"
                    @change="inputValueChange($event, 'waterCostUnit')"
                  >
                    <option
                      value="dollarsPerCubicMeter"
                      v-html="$t('unit.dollarsPerCubicMeter')"
                    ></option>
                    <option
                      value="dollarsPer1000USGallons"
                      v-html="$t('unit.dollarsPer1000USGallons')"
                    ></option>
                  </select>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="operatorWage" v-html="$t('operatorWage')"></label>
            <span v-html="$t('operatorWageExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="operatorWage"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="operatorWage"
                />
                <div class="input-group-append">
                  <span class="input-group-text">$ / h</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="co2Cost" v-html="$t('co2Cost')"></label>
            <span v-html="$t('co2CostExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="co2Cost"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="co2Cost"
                />
                <div class="input-group-append">
                  <select
                    class="custom-select"
                    id="co2CostUnit"
                    :value="co2CostUnit"
                    @change="inputValueChange($event, 'co2CostUnit')"
                  >
                    <option value="dollarsPerTon" v-html="$t('unit.dollarsPerTon')"></option>
                    <option
                      value="dollarsPerShortTonOption"
                      v-html="$t('unit.dollarsPerShortTonOption')"
                    ></option>
                  </select>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <ActionButtons
        :hasNext="true"
        :hasPrevious="true"
        :previousStep="previousStep"
      />
    </ValidationObserver>
  </form>
</template>

<script>
import ActionButtons from "@/components/calculator/ActionButtons.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Step3",
  components: {
    ActionButtons,
    ValidationObserver,
    ValidationProvider
  },
  props: [
    "gasPriceUnit",
    "waterCostUnit",
    "co2CostUnit",
    "previousStep",
    "nextStep"
  ],
  data() {
    return {
      gasPrice: this.$store.getters.gasPrice,
      waterCost: this.$store.getters.waterCost,
      operatorWage: this.$store.getters.operatorWage,
      co2Cost: this.$store.getters.co2Cost
    };
  },
  mounted() {
    this.$refs.gasPrice.focus();
  },
  methods: {
    inputValueChange(event, key) {
      this.$emit(`update:${key}`, event.target.value);
    },
    async submit() {
      const isValid = await this.$refs.step3.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("setGasPrice", this.gasPrice);
      this.$store.commit("setWaterCost", this.waterCost);
      this.$store.commit("setOperatorWage", this.operatorWage);
      this.$store.commit("setCo2Cost", this.co2Cost);
      this.nextStep();
    }
  }
};
</script>

<style lang="scss" scoped>
.is-danger {
  border: 1px solid red !important;
}
</style>
