export default {
  methods: {
    handleResize(id, reference) {
      const component = document.getElementById(id);
      const elements = component.querySelectorAll("input,select,textarea");
      const invalidListener = function() {
        const offsetCss = getComputedStyle(
          document.documentElement
        ).getPropertyValue("--top-offset");
        const offsetValue = parseInt(offsetCss.replace("px", ""));
        window.scrollTo(0, reference.offsetTop - offsetValue);
      };
      for (let i = elements.length; i--; ) {
        elements[i].addEventListener("invalid", invalidListener);
      }
    },
    setWindowPositionOnInvalid(id, reference) {
      window.addEventListener("resize", () => {
        this.handleResize(id, reference);
      });
      this.handleResize(id, reference);
    },
    showInvalidMessage(ref) {
      try {
        const msg = this.errors.first(ref) ? this.errors.first(ref) : "";
        this.$refs[ref].setCustomValidity(msg);
      } catch (error) {}
    }
  }
};
