<template>
  <span class="radio-inline mr-4">
    <label class="radio-container">
      <span class="label" v-html="$t(`${i18nKey}`)"></span>
      <input
        type="radio"
        :name="name"
        :value="value"
        :checked="checked"
        v-on:change="onValueChange"
      />
      <span class="checkmark"></span>
    </label>
  </span>
</template>

<script>
export default {
  name: "CustomRadioButton",
  props: ["name", "i18nKey", "value", "checked", "action"],
  methods: {
    onValueChange(event) {
      this.action(event.target.value);
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: $large_screen_width) {
  .label {
    font-size: 12.8px;
  }
}

/************************
* Custom Radio Button
************************/
.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: $grey;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radio-container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked ~ .checkmark {
  background-color: $grey;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-container .checkmark:after {
  content: "\f00c";
  font-family: "FontAwesome", sans-serif;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked ~ .checkmark:after {
  display: block;
}
</style>
