/* eslint-disable */

const messages = {
  _default: field => `The value is not valid.`,
  after: (field, [target, inclusion]) =>
    `The must be after ${inclusion ? "or equal to " : ""}${target}.`,
  alpha: field => `The field may only contain alphabetic characters.`,
  alpha_dash: field =>
    `The field may contain alpha-numeric characters as well as dashes and underscores.`,
  alpha_num: field => `The field may only contain alpha-numeric characters.`,
  alpha_spaces: field => `The field may only contain alphabetic characters as well as spaces.`,
  before: (field, [target, inclusion]) =>
    `The must be before ${inclusion ? "or equal to " : ""}${target}.`,
  between: (field, [min, max]) => `The field must be between ${min} and ${max}.`,
  confirmed: field => `The confirmation does not match.`,
  credit_card: field => `The field is invalid.`,
  date_between: (field, [min, max]) => `The must be between ${min} and ${max}.`,
  date_format: (field, [format]) => `The must be in the format ${format}.`,
  decimal: (field, [decimals = "*"] = []) =>
    `The field must be numeric and may contain ${
      !decimals || decimals === "*" ? "" : decimals
    } decimal points.`,
  digits: (field, [length]) => `The field must be numeric and exactly contain ${length} digits.`,
  dimensions: (field, [width, height]) => `The field must be ${width} pixels by ${height} pixels.`,
  email: field => `The field must be a valid email.`,
  customEmail: field => `The field must be a .com or .ca email.`,
  excluded: field => `The field must be a valid value.`,
  ext: field => `The field must be a valid file.`,
  image: field => `The field must be an image.`,
  included: field => `The field must be a valid value.`,
  integer: field => `The field must be an integer.`,
  ip: field => `The field must be a valid ip address.`,
  length: (field, [length, max]) => {
    if (max) {
      return `The length must be between ${length} and ${max}.`;
    }

    return `The length must be ${length}.`;
  },
  max: (field, [length]) => `The field may not be greater than ${length} characters.`,
  max_value: (field, [max]) => `The field must be ${max} or less.`,
  mimes: field => `The field must have a valid file type.`,
  min: (field, [length]) => `The field must be at least ${length} characters.`,
  min_value: (field, [min]) => `The field must be ${min} or more.`,
  numeric: field => `The field may only contain numeric characters.`,
  regex: field => `The field format is invalid.`,
  required: field => `The field is required.`,
  required_if: (field, [target]) =>
    `The field is required when the ${target} field has this value.`,
  size: (field, [size]) => `The size must be less than ${size}.`,
  url: field => `The field is not a valid URL.`
};

export default messages;
