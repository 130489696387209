<template>
  <div v-if="modalShown">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5
                class="modal-title text-dark"
                id="exampleModalLabel"
                v-html="$t('confirmation')"
              ></h5>
              <button
                type="button"
                class="close"
                @click="close"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <form @submit.prevent="submit">
              <div class="modal-body">
                <div class="row align-items-end pb-4">
                  <div class="col-8">
                    <label
                      class="text-dark mb-3"
                      for="email"
                      v-html="$t('email')"
                    ></label>
                    <input
                      type="email"
                      id="emailTo"
                      ref="emailTo"
                      name="emailTo"
                      class="form-control"
                      v-model="email"
                      v-validate
                      :oninvalid="showInvalidMessage('emailTo')"
                      required
                    />
                  </div>
                  <div class="col-4">
                    <select v-model="emailLocale" class="custom-select">
                      <option
                        v-for="(locale, index) in $i18n.availableLocales"
                        :key="index"
                        :value="locale"
                      >
                        {{ locale }}
                      </option>
                    </select>
                  </div>
                </div>
                <!-- Alert -->
                <div
                  class="row"
                  v-bind:style="[
                    isAlertDisplayed
                      ? { display: 'block' }
                      : { display: 'none' }
                  ]"
                >
                  <div class="col">
                    <div class="alert alert-success" role="alert">
                      <span v-html="$t('results.alert')"></span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-dark" @click="close()">
                  <span v-html="$t('close')"></span>
                </button>
                <button type="submit" class="btn btn-info">
                  <span v-html="$t('results.email')"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomValidity from "@/mixins/CustomValidity.js";

export default {
  name: "SendEmailModal",
  props: ["modalShown", "sendResultsToEmail"],
  mixins: [CustomValidity],
  data() {
    return {
      email: "",
      emailLocale: this.$i18n.locale,
      isAlertDisplayed: false
    };
  },
  mounted() {
    this.$validator.validateAll();
  },
  methods: {
    close() {
      this.$emit(`update:modalShown`, false);
    },
    async submit() {
      try {
        this.sendResultsToEmail(this.email, this.emailLocale);
        this.isAlertDisplayed = true;
      } catch (error) {
        console.log(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.close {
  outline-style: none;
}
</style>
