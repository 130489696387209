<template>
  <a
    href=""
    @click.prevent="changeLanguage"
    class="ml-auto pl-3"
    :class="[isBlue ? 'border-white text-white' : 'border-black']"
  >
    <span>{{ otherLang }}</span>
  </a>
</template>

<script>
export default {
  name: "LanguageSwitcher",
  props: ["isBlue"],
  data: () => ({
    otherLang: null
  }),
  mounted() {
    this.otherLang = this.$i18n.locale === "en" ? "fr" : "en";
  },
  methods: {
    changeLanguage() {
      this.$i18n.locale = this.otherLang;
      this.$root.$emit("locale", this.$i18n.locale);
      this.$router.push({ params: { language: this.$i18n.locale } });
      this.$cookies.set("locale", this.$i18n.locale, "30d");
      this.otherLang = this.otherLang === "en" ? "fr" : "en";
    }
  }
};
</script>

<style lang="scss" scoped>
a {
  text-decoration: underline;
}

a.border-white {
  border-left: 1px solid white;
}

a.border-black {
  border-left: 1px solid black;
}
</style>
