import Vue from "vue";

/****************
 * Bootstrap
 ****************/
import "bootstrap";
import "bootstrap/scss/bootstrap.scss";
import BootstrapVue from "bootstrap-vue";

Vue.use(BootstrapVue);

/****************
 * Styles
 ****************/
import "@/styles/_buttons.scss";
import "@/styles/_calculator.scss";
import "@/styles/_fonts.scss";
import "@/styles/_forms.scss";
import "@/styles/_headers.scss";
import "@/styles/_spacings.scss";
import "@/styles/_squares.scss";

/****************
 * Vue Directive Tooltip
 ****************/
import Tooltip from "vue-directive-tooltip";
import "vue-directive-tooltip/dist/vueDirectiveTooltip.css";

Vue.use(Tooltip);

/****************
 * Font awesome
 ****************/
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faInfo,
  faPlusCircle,
  faQuoteLeft,
  faQuoteRight,
  faShareSquare,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import {
  faComment,
  faMinusSquare,
  faPlayCircle,
  faPlusSquare
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faComment,
  faLinkedinIn,
  faInfo,
  faPlayCircle,
  faPlusCircle,
  faQuoteLeft,
  faQuoteRight,
  faPlusSquare,
  faMinusSquare,
  faShareSquare,
  faTimesCircle
);

Vue.component("font-awesome-icon", FontAwesomeIcon);

import "./utils/veevalidate.js";

import numeral from "./utils/numeral";

import { store } from "./store";

import i18n from "./i18n";

import App from "./App.vue";

import VueMatchHeights from "vue-match-heights";

import "vue-tel-input/dist/vue-tel-input.css";
import VueTelInput from "vue-tel-input";

import vueSmoothScroll from "vue2-smooth-scroll";

import VueScrollactive from "vue-scrollactive";

import VueCookies from "vue-cookies";

import numFormat from "vue-filter-number-format";

import vueCountryRegionSelect from "vue-country-region-select";

import router from "./router";
import middleware from "./middleware";

Vue.filter("numFormat", numFormat);

Vue.use(VueMatchHeights);

Vue.use(vueSmoothScroll);

Vue.use(VueScrollactive);

Vue.use(VueCookies);

Vue.use(vueCountryRegionSelect);

Vue.use(VueTelInput);

Vue.mixin({
  data: function() {
    return {
      numeral
    };
  }
});

middleware(router);

Vue.config.productionTip = false;

/*
Vue.config.ignoredElements = [
  "aquafilm-calculator"
];
*/

new Vue({
  router,
  i18n,
  store,
  render: h => h(App),
  beforeMount() {
    this.numeral.locale(this.$i18n.locale);
  },
  mounted() {
    document.dispatchEvent(new Event("x-app-rendered"));
    this.$root.$on("locale", locale => {
      this.numeral.locale(locale);
    });
    document.addEventListener("mousewheel", function(event) {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    });
  }
}).$mount("#app");
