<template>
  <div id="footer">
    <div class="bg-grey">
      <div class="container">
        <div class="row align-items-center py-2 py-lg-5">
          <!-- Aquafilm + Distributor logos -->
          <div class="col-lg-5 my-3 text-center">
            <div class="d-flex align-items-center">
              <div class="d-flex mx-auto mx-lg-0">
                <div class="brand-logo">
                  <img src="@/assets/aquafilm.svg" />
                </div>
                <div
                  class="vertical-line mx-3"
                  v-if="$route.params.distributor"
                ></div>
                <div
                  v-bind:class="getBrandLogoClass"
                  v-if="$route.params.distributor"
                >
                  <img
                    :src="
                      require(`@/assets/distributors/${
                        $route.params.distributor
                      }.png`)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- Links -->
          <div class="col-lg-6 my-3 text-center text-lg-right">
            <div class="flex-container">
              <a href="" @click.prevent="changeRegion" class="item">
                <span
                  class="text-nowrap"
                  v-html="$t('changeRegionLink')"
                ></span>
              </a>
              <span class="d-none d-lg-block mx-2">|</span>
              <span v-if="!$route.name.includes('StudyCase')">
                <a
                  href="#expert"
                  v-smooth-scroll="{ offset: -165 }"
                  class="item"
                >
                  <span v-html="$t('contactLink')"></span>
                </a>
              </span>
              <span v-else>
                <router-link
                  :to="{ name: 'Home', hash: '#expert' }"
                  class="item"
                >
                  <span v-html="$t('contactLink')"></span>
                </router-link>
              </span>
              <span class="d-none d-lg-block mx-2">|</span>
              <a
                href="https://www.linkedin.com/showcase/aquafilm-v"
                target="_blank"
                class="item"
              >
                <span class="mr-2" v-html="$t('followUs')"></span>
                <img src="@/assets/linkedin.png" width="25" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- All logos -->
    <div class="container mt-3" v-if="!$route.params.distributor">
      <div class="row align-items-center justify-content-center text-center">
        <div class="col-lg-1-10 text-center">
          <img
            class="logo w-100"
            src="@/assets/distributors/blue-theme/aqua-serv.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo bigger-logo"
            src="@/assets/distributors/blue-theme/specifiedmagnus.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo w-100"
            src="@/assets/distributors/blue-theme/chemsystemshi.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo industrial-water-logo"
            src="@/assets/distributors/blue-theme/industrialwater.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo bigger-logo"
            src="@/assets/distributors/blue-theme/pecowater.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo w-100"
            src="@/assets/distributors/blue-theme/summitlaboratories.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo w-100"
            src="@/assets/distributors/blue-theme/guardiancsc.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo bigger-logo"
            src="@/assets/distributors/magnus.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo bigger-logo"
            src="@/assets/distributors/blue-theme/premierwater.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo weas-engineering"
            src="@/assets/distributors/blue-theme/weasengineering.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo"
            src="@/assets/distributors/blue-theme/watertechusa.png"
          />
        </div>
        <div class="col-lg-1-10 text-center">
          <img
            class="logo"
            src="@/assets/distributors/blue-theme/aquaconcept.png"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  computed: {
    getBrandLogoClass() {
      const isLargeLogo =
        this.$route.params.distributor === "premierwater" ||
        this.$route.params.distributor === "pecowater";

      return isLargeLogo ? "bigger-brand-logo" : "brand-logo";
    }
  },
  methods: {
    changeRegion() {
      this.$root.$emit("openRegionSelectorModal", true);
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-grey {
  background-color: $grey;
}

/**
* Logo width
*/
.logo {
  max-width: 125px;
  padding-top: 15px;
  padding-bottom: 15px;
}

.bigger-logo {
  width: 120%;
}

.industrial-water-logo {
  width: 45%;
}

.weas-engineering {
  width: 45%;
  max-width: 45px;
}

/**
* 10 columns grid
*/
.col-lg-1-10 {
  width: 100% !important;
  float: left;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: $medium_screen_width) {
  .col-lg-1-10 {
    width: 16.66666666% !important;
  }
}

@media (min-width: $large_screen_width) {
  .col-lg-1-10 {
    width: 16.66666666% !important;
  }
}

/**
* Links 
*/
.flex-container {
  display: flex;
  justify-content: flex-end;

  @media (max-width: $large_screen_width) {
    flex-direction: column;
    justify-content: center;

    .item {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
  }
}

@media (max-width: $small_screen_width) {
  #footer {
    padding-bottom: 56px; // Sticky contact height
  }
}
</style>
