import * as c from "@/constants.js";

export default {
  methods: {
    getPricePerTons(value, unit) {
      if (unit === "dollarsPerTon") {
        return value;
      }
      return value / c.SHORT_TON_TO_TON;
    },
    getPricePerCubicMeter(value, unit) {
      if (unit === "dollarsPerCubicMeter") {
        return value;
      }
      if (unit === "dollarsPer1000USGallons") {
        return value / c.US_GALLONS_TO_CUBIC_METER / 1000;
      }
      return value / c.CUBIC_FEET_TO_CUBIC_METER / 1000;
    },
    getKgPerYear(value, unit) {
      if (unit === "kgPerYear") {
        return value;
      }
      return value * c.LBS_TO_KG;
    },
    getHorsePower(value, unit) {
      if (unit === "steamPower") {
        return value;
      }
      return value * c.STEAM_POWER_TO_HORSE_POWER;
    }
  }
};
