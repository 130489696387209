<template>
  <scrollactive
    id="sticky-menu"
    class="bg-primary"
    active-class="is-active"
    :offset="offset"
  >
    <div class="container h-100">
      <div class="sticky-menu-container">
        <a href="#calculator" class="scrollactive-item">
          <span class="" v-html="$t('menu.calculator')"></span>
        </a>
        <a href="#benefits" class=" scrollactive-item">
          <span class="" v-html="$t('menu.benefits')"></span>
        </a>
        <a href="#faq" class=" scrollactive-item">
          <span class="" v-html="$t('menu.faq')"></span>
        </a>
      </div>
    </div>
  </scrollactive>
</template>

<script>
import ResizeMixin from "@/mixins/ResizeMixin.js";

const menuHeight = 115;
const stickyMenuHeight = 50;

export default {
  name: "StickyMenu",
  mixins: [ResizeMixin],
  data() {
    return {
      offset: menuHeight + stickyMenuHeight
    };
  },
  methods: {
    handleScroll() {
      const stickyMenu = document.getElementById("sticky-menu");
      const header = document.getElementById("header");
      const headerOffsetTop = header.offsetHeight;
      const stickyClass = "sticky";

      if (window.pageYOffset > headerOffsetTop) {
        stickyMenu.classList.add(stickyClass);
      } else {
        stickyMenu.classList.remove(stickyClass);
      }
    }
  },
  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }
};
</script>

<style lang="scss" scoped>
#sticky-menu {
  position: relative;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
  height: 50px;
  border-top: 1px solid #fff;
  z-index: $sticky-menu-zindex;
}

.sticky-menu-container {
  display: flex;
  justify-content: center;
  height: 100%;
}

.is-active::after {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  left: -25px;
  width: 50px;
  height: 100%;
  background-color: #6273a7;
  z-index: -1;
}

.scrollactive-item {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  color: white;
  margin-right: 110px;
  margin-left: 110px;

  &:hover {
    text-decoration: none;
    color: white;

    &:after {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      left: -25px;
      width: 50px;
      height: 100%;
      background-color: #6273a7;
      z-index: -1;
    }
  }
}
</style>
