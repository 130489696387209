<template>
  <div id="study-case">
    <div class="container">
      <!-- Title -->
      <div class="row mt-5">
        <div class="col">
          <h5 class="title">
            <span
              class="text-uppercase text-dark-dark"
              v-html="$t(`studyCases[${id}].title`)"
            ></span>
            <span v-html="$t(`studyCases[${id}].subtitle`)"></span>
          </h5>
        </div>
      </div>

      <!-- H1 -->
      <div class="row">
        <div class="col">
          <h4
            class="text-uppercase text-primary"
            v-html="$t(`studyCases.${id}.h1`)"
          ></h4>
        </div>
      </div>

      <!-- Key Points -->
      <div class="keypoints">
        <h5 v-html="$t(`studyCase.keyPoints`)"></h5>
        <div class="small-square square-1 d-none d-md-block"></div>
        <div class="small-square square-2 d-none d-md-block"></div>
      </div>
    </div>

    <div class="bg-light-grey p-5">
      <div class="container">
        <!-- Military Base -->
        <div v-if="id === 0">
          <div class="row text-center" v-match-heights="{ el: ['.col-md-3'] }">
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto">
                <img src="@/assets/icons/product-consumption.svg" height="90" />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases[${id}].bullets[0]`)"
                ></div>
                <div class="stats">-75%</div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img
                  src="@/assets/icons/decrease-number-products.svg"
                  height="90"
                />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[1]`)"
                ></div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img src="@/assets/icons/simplification.svg" height="90" />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[2]`)"
                ></div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img src="@/assets/icons/health.svg" height="90" />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[3]`)"
                ></div>
              </div>
            </div>
          </div>
        </div>

        <!-- Wood drying Kiln -->
        <div v-else>
          <div class="row text-center" v-match-heights="{ el: ['.col-md-3'] }">
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img src="@/assets/icons/blowdown-rate.svg" height="90" />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[0]`)"
                ></div>
                <div class="stats">-80%</div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img src="@/assets/icons/carry-over-problems.svg" height="90" />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[1]`)"
                ></div>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img src="@/assets/icons/energy-savings.svg" height="90" />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[2]`)"
                ></div>
                <div class="stats">-1 000 000</div>
                <h5 v-html="$t(`studyCase.btu/day`)"></h5>
              </div>
            </div>
            <div class="col-12 col-sm-6 col-md-3 d-flex">
              <div class="my-auto my-sm-0">
                <img
                  src="@/assets/icons/process-improvements.svg"
                  height="90"
                />
                <div
                  class="keypoint-title"
                  v-html="$t(`studyCases.${id}.bullets[3]`)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container">
      <!-- The Customer -->
      <div id="customer">
        <div class="row align-items-center my-5">
          <div class="col">
            <div class="row mb-4">
              <div class="col customer-title">
                <div v-html="$t(`studyCase.client`)"></div>
                <div v-html="$t(`studyCases.${id}.client`)"></div>
              </div>
            </div>
            <div class="position-relative">
              <img :src="image" class="w-100" />
              <div class="large-square large-square-1 d-none d-md-block"></div>
              <div class="small-square small-square-1 d-none d-md-block"></div>
              <div class="small-square small-square-2 d-none d-md-block"></div>
              <div class="small-square small-square-3 d-none d-md-block"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Background -->
      <div class="row align-items-center my-5">
        <div class="col-md-7 col-lg-8">
          <div class="row mb-2">
            <div class="col">
              <div class="subtitle" v-html="$t(`studyCase.scenario`)"></div>
            </div>
          </div>
          <p v-html="$t(`studyCases.${id}.scenario`)"></p>
        </div>
        <div class="col-md-5 col-lg-4">
          <div class="quote">
            <span
              class="mb-2"
              v-html="$t('quotes.left')"
              v-if="$i18n.locale === 'fr'"
            ></span>
            <font-awesome-icon icon="quote-left" v-else />
          </div>
          <div
            class="quote-text"
            v-html="$t(`studyCases.${id}.scenarioQuote`)"
          ></div>
          <div class="quote">
            <span
              class="mb-2"
              v-html="$t('quotes.right')"
              v-if="$i18n.locale === 'fr'"
            ></span>
            <font-awesome-icon icon="quote-right" v-else />
          </div>
        </div>
      </div>

      <!-- Solution -->
      <div class="row align-items-center my-5">
        <div class="col-md-7 col-lg-8">
          <div class="row mb-2">
            <div class="col">
              <div class="subtitle" v-html="$t(`studyCase.solution`)"></div>
            </div>
          </div>
          <p v-html="$t(`studyCases.${id}.solution`)"></p>
        </div>
        <div class="col-md-5 col-lg-4">
          <!-- Military Base -->
          <div v-if="id === 0">
            <div class="quote">
              <span
                class="mb-2"
                v-html="$t('quotes.left')"
                v-if="$i18n.locale === 'fr'"
              ></span>
              <font-awesome-icon icon="quote-left" v-else />
            </div>
            <div
              class="quote-text"
              v-html="$t(`studyCases.${id}.solutionQuote`)"
            ></div>
            <div class="quote">
              <span
                class="mb-2"
                v-html="$t('quotes.right')"
                v-if="$i18n.locale === 'fr'"
              ></span>
              <font-awesome-icon icon="quote-right" v-else />
            </div>
          </div>
          <!-- Wood drying Kiln -->
          <div v-else>
            <p class="text-secondary mb-2">
              <b v-html="$t(`studyCases.${id}.solutionQuote`)"></b>
            </p>
            <img src="@/assets/tubes.jpg" class="mb-2 w-100" />
          </div>
        </div>
      </div>

      <!-- Results -->
      <div class="row mt-5 mb-4">
        <div class="col">
          <div class="row mb-2">
            <div class="col">
              <div class="subtitle" v-html="$t(`studyCase.results`)"></div>
            </div>
          </div>
          <p v-html="$t(`studyCases.${id}.results`)"></p>
        </div>
      </div>
      <div v-if="id === 1">
        <div class="row">
          <div class="col">
            <h5 class="text-primary">
              <u v-html="$t(`studyCase.graphic.title`)"></u>
            </h5>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-10 col-lg-8">
            <img src="@/assets/results.jpg" class="w-100" />
          </div>
        </div>
        <div class="row mb-5">
          <div class="col-6 col-sm-6 col-md-5 col-lg-4 no-margin">
            <div class="row ml-md-4">
              <div class="col">
                <hr class="black-legend-border" />
              </div>
              <div class="col">
                <small v-html="$t(`studyCase.graphic.boilerCycles`)"></small>
              </div>
            </div>
            <div class="row ml-md-4">
              <div class="col">
                <hr class="green-legend-border" />
              </div>
              <div class="col">
                <small
                  v-html="$t(`studyCase.graphic.condensateConductivity`)"
                ></small>
              </div>
            </div>
          </div>
          <div class="col-6 col-sm-6 col-md-5 col-lg-4 no-margin">
            <div
              class="text-white text-center my-2 ml-md-auto mr-md-5 p-2 results-legend"
            >
              <span v-html="$t(`studyCase.graphic.note`)"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Conclusion -->
      <div class="row my-5">
        <div class="col">
          <div class="conclusion-border p-4">
            <div class="row mb-2">
              <div class="col">
                <div class="subtitle" v-html="$t(`studyCase.conclusion`)"></div>
              </div>
            </div>
            <p v-html="$t(`studyCases.${id}.conclusion`)"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StudyCase",
  metaInfo() {
    return {
      title: this.$i18n.t(`studyCases.${this.id}.meta.title`),
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content: this.$i18n.t(`studyCases.${this.id}.meta.description`)
        },
        {
          name: "keywords",
          content: this.$i18n.t(`studyCases.${this.id}.meta.keywords`)
        }
      ]
    };
  },
  data() {
    const id = parseInt(this.$route.params.id) - 1;
    const ext = id === 0 ? "png" : "jpg";
    return {
      id: id,
      image: require(`@/assets/studyCase${this.$route.params.id}.${ext}`)
    };
  }
};
</script>

<style lang="scss" scoped>
#study-case {
  padding-top: var(--study-case-top-offset);
}

.title {
  font-size: 22px;
}

.subtitle {
  color: $primary;
  font-size: 22px;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
  font-weight: bold;
}

/**
* Quotes
*/
.quote {
  display: flex;
  flex-direction: row;
  font-family: "arial_rounded_mt_stdregular", sans-serif;

  > * {
    margin-left: 10px;
    margin-right: 10px;
  }

  span {
    font-size: 60px;
  }
  svg {
    font-size: 40px;
  }
}

.quote:before,
.quote:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid #000;
  margin: auto;
}

.quote-text {
  color: $primary;
  font-size: 24px;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

/**
* Keypoints
*/
.keypoints {
  position: relative;
  height: 125px;
  width: 125px;
  background-color: $icon;
  margin-top: 75px;

  > h5 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 30px;
  }

  .square-1 {
    bottom: calc(-1 * #{$small-square-size});
    left: calc(-1 * #{$small-square-size});
  }

  .square-2 {
    bottom: calc(-1 * #{$small-square-size} / 2);
    right: calc(-1 * #{$small-square-size} / 2);
  }
}

.keypoint-title {
  color: $icon;
  font-size: 22px;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
  margin-top: 0.5rem;
}

.stats {
  font-size: 32px;
  font-weight: bold;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
  color: $primary;
}

/**
* The Customer
*/
#customer {
  margin-bottom: $large-square-size;

  .customer-title {
    color: $primary;
    font-size: 26px;
    font-family: "arial_rounded_mt_stdregular", sans-serif;
    font-weight: bold;
  }

  .large-square-1 {
    background-color: $icon;
    opacity: 0.8;
    bottom: calc(-1 * #{$large-square-size} / 2);
    left: 20px;
  }

  .small-square-1 {
    bottom: 150px;
    left: calc(-1 * #{$small-square-size} / 2);
  }

  .small-square-2 {
    top: 50px;
    right: calc(-1 * #{$small-square-size} / 2);
  }

  .small-square-3 {
    bottom: 25px;
    left: 75px;
  }
}

/**
* Results legend
*/
.black-legend-border {
  border-top: 2px solid black;
}

.green-legend-border {
  border-top: 2px solid #2b8b4b;
}

.results-legend {
  font-size: 14px;
  background-color: $primary;
  max-width: 200px;
}

/**
* Conclusion
*/
.conclusion-border {
  border: 1px solid $secondary;
}

hr {
  border-top: 1px solid $secondary;
}
</style>
