import distributors from "../distributors.json";

export default {
  methods: {
    initialize() {
      const geolocation = this.$cookies.get("geolocation");

      if (!geolocation) {
        this.$store.commit("setCookiesEnabled", false);
        return;
      }

      const country = geolocation.country;
      const region = geolocation.region;
      const areCookiesEnabled = this.$cookies.get("areCookiesEnabled");

      this.$store.commit("setCountry", country);
      this.$store.commit("setRegion", region);
      this.$store.commit("setCookiesEnabled", areCookiesEnabled);
      this.goToDistributorPage(country, region);
    },
    goToDistributorPage(country, region) {
      const place = { country, region };
      const name = this.getDistributorName(country, region);
      if (name) {
        if (!this.$route.path.includes(name)) {
          this.$router.push({ name: "Distributor", params: { distributor: name } });
        }
      } else if (this.$route.name !== "Home" && this.$route.name !== "StudyCase") {
        this.$router.push({ name: "Home" });
      }
      this.$root.$emit("distributor", place);
    },
    getDistributorLabel(country, region) {
      for (let i = 0; i < distributors.length; i++) {
        if (
          distributors[i].country === country &&
          distributors[i].region === region
        ) {
          return distributors[i].nameLabel;
        }
      }
      return null;
    },
    getDistributorName(country, region) {
      for (let i = 0; i < distributors.length; i++) {
        if (
          distributors[i].country === country &&
          distributors[i].region === region
        ) {
          return distributors[i].name;
        }
      }
      return null;
    },
    setCookies(country, region) {
      const geolocation = {
        country: country,
        region: region,
      };
      this.$cookies.set("geolocation", geolocation, "30d");
    }
  }
};
