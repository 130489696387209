export default {
  data() {
    return {
      topOffset: 0
    };
  },
  mounted() {
    this.handleScreenResize();
  },
  methods: {
    getHeaderOffsetHeight() {
      const header = document.getElementById("header");
      if (header) {
        return header.offsetHeight;
      }
      return 0;
    },
    getStickyMenuOffsetHeight() {
      const stickyMenu = document.getElementById("sticky-menu");
      if (stickyMenu) {
        return stickyMenu.offsetHeight;
      }
      return 0;
    },
    handleScreenResize() {
      const navbarHeight = 115;
      const headerHeight = this.getHeaderOffsetHeight();
      const stickyMenuHeight = this.getStickyMenuOffsetHeight();
      const topOffset = navbarHeight + stickyMenuHeight;

      this.topOffset = topOffset;
      this.$root.$emit("offset", topOffset);

      document.documentElement.style.setProperty(
        "--top-offset",
        `${topOffset}px`
      );
      document.documentElement.style.setProperty(
        "--sticky-menu-offset",
        `${navbarHeight + headerHeight}px`
      );
      document.documentElement.style.setProperty(
        "--study-case-top-offset",
        `${navbarHeight}px`
      );
    }
  },
  created() {
    window.addEventListener("resize", this.handleScreenResize);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleScreenResize);
  }
};
