<template>
  <div id="video-player">
    <!-- Title -->
    <div class="container">
      <div class="row py-5">
        <div class="col text-center">
          <h3 v-html="$t('benefitsTitle')"></h3>
        </div>
      </div>
    </div>

    <!-- Video Preview -->
    <div class="video-container">
      <img src="@/assets/video-poster.png" />
      <button
        @click="showFullVideo()"
        class="btn btn-play"
        v-bind:style="[
          isFullScreenVideoShown ? { zIndex: '0' } : { zIndex: '3' }
        ]"
      >
        <font-awesome-icon class="fa-4x" :icon="['far', 'play-circle']" />
      </button>
    </div>

    <!-- Full Screen Video -->
    <transition>
      <div class="full-video-container" v-if="isFullScreenVideoShown">
        <iframe
          id="fr-player"
          type="text/html"
          width="100%"
          height="100%"
          allow="autoplay; fullscreen"
          src="https://www.youtube.com/embed/vvq6p9__qOI?autoplay=1&enablejsapi=1&origin=https://www.aquafilm.ca"
          frameborder="0"
          v-if="$i18n.locale === 'fr'"
        ></iframe>

        <iframe
          id="en-player"
          type="text/html"
          width="100%"
          height="100%"
          allow="autoplay; fullscreen"
          src="https://www.youtube.com/embed/868G_cyTm9A?autoplay=1&enablejsapi=1&origin=https://www.aquafilm.ca"
          frameborder="0"
          v-else
        ></iframe>
        <button @click="closeFullVideo" class="btn btn-close">
          <font-awesome-icon class="icon fa-3x" icon="times-circle" />
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
/***********
 * Add youtube API script
 ***********/
const tag = document.createElement("script");
tag.src = "https://www.youtube.com/iframe_api";

const firstScriptTag = document.getElementsByTagName("script")[0];
firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

export default {
  name: "VideoPlayer",
  data() {
    return {
      youtubeState: window.youtubeState,
      isFullScreenVideoShown: false,
      frenchVideoId: "_JEgUX1C2dM",
      englishVideoId: "rXEP4_-gGkg"
    };
  },
  mounted() {
    // youtube api is already ready
    if (window.youtubeState.isYoutubeApiLoaded) {
      this.loadVideo();
    }
  },
  watch: {
    "youtubeState.isYoutubeApiLoaded"(isLoaded) {
      if (isLoaded) {
        this.loadVideo();
      }
    }
  },
  methods: {
    loadVideo() {
      new YT.Player("fr-player");
      new YT.Player("en-player");
    },
    closeFullVideo() {
      this.isFullScreenVideoShown = false;
    },
    showFullVideo() {
      this.isFullScreenVideoShown = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.video-container {
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.btn-play {
  position: absolute;
  width: 72px;
  height: 72px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  background-color: #ececec;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 60%;
    color: black;
  }
}

.btn-play:focus,
.btn-pla:active {
  outline: none !important;
  box-shadow: none;
}

/***********
* Full video
***********/
.full-video-container {
  background-color: black;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999999;
}

.btn-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 75px;
  height: 75px;
  background-color: $primary;
}
</style>
