<template>
  <form class="step" @submit.prevent="submit">
    <ValidationObserver ref="step4" v-slot="{ invalid }">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label
              for="conventionalProductsQuantity"
              v-html="$t('conventionalProductsQuantity')"
            ></label>
            <span v-html="$t('conventionalProductsQuantityExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="conventionalProductsQuantity"
                  ref="conventionalProductsQuantity"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="conventionalProductsQuantity"
                />
                <div class="input-group-append">
                  <select
                    class="custom-select"
                    id="productsQuantityUnit"
                    :value="productsQuantityUnit"
                    @change="inputValueChange($event, 'productsQuantityUnit')"
                  >
                    <option
                      value="kgPerYear"
                      v-html="$t('unit.kgPerYear')"
                    ></option>
                    <option
                      value="lbsPerYear"
                      v-html="$t('unit.lbsPerYear')"
                    ></option>
                  </select>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="capacity">
                <span v-html="$t('sulfitePhosphateProportions')"></span>
                <span
                  v-tooltip="$t('tooltips.sulfitePhosphateProportions')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('sulfitePhosphateProportionsExample')"></span>
            <ValidationProvider
              :rules="{
                required: true,
                min_value: 0,
                max_value: conventionalProductsQuantity
              }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="sulfitePhosphateProportions"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="sulfitePhosphateProportions"
                />
                <div class="input-group-append">
                  <select
                    class="custom-select"
                    id="sulfitePhosphateProportionsUnit"
                    :value="sulfitePhosphateProportionsUnit"
                    @change="
                      inputValueChange(
                        $event,
                        'sulfitePhosphateProportionsUnit'
                      )
                    "
                  >
                    <option
                      value="kgPerYear"
                      v-html="$t('unit.kgPerYear')"
                    ></option>
                    <option
                      value="lbsPerYear"
                      v-html="$t('unit.lbsPerYear')"
                    ></option>
                  </select>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="pumps" v-html="$t('pumps')"></label>
            <span v-html="$t('pumpsExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="pumps"
                  pattern="\d*"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="pumps"
                />
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <label
              for="preparationControlTime"
              v-html="$t('preparationControlTime')"
            ></label>
            <span v-html="$t('preparationControlTimeExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="preparationControlTime"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="preparationControlTime"
                />
                <div class="input-group-append">
                  <span
                    class="input-group-text"
                    v-html="$t('unit.hours')"
                  ></span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="alkalinity">
                <span v-html="$t('alkalinity')"></span>
                <span
                  v-tooltip="$t('tooltips.alkalinity')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('alkalinityExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="alkalinity"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="alkalinity"
                />
                <div class="input-group-append">
                  <span class="input-group-text">ppm</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <div class="d-inline-flex align-items-center">
              <label for="silica">
                <span v-html="$t('silica')"></span>
                <span
                  v-tooltip="$t('tooltips.silica')"
                  class="tooltip-info ml-2"
                >
                  <font-awesome-icon icon="info" class="" />
                </span>
              </label>
            </div>
            <span v-html="$t('silicaExample')"></span>
            <ValidationProvider
              :rules="{ required: true, min_value: 0, max_value: 100 }"
              v-slot="{ errors }"
            >
              <div class="input-group mt-3">
                <input
                  id="silica"
                  step="any"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="silica"
                />
                <div class="input-group-append">
                  <span class="input-group-text">ppm</span>
                </div>
              </div>
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>
          </div>
        </div>
      </div>
      <ActionButtons
        :hasNext="true"
        :hasPrevious="true"
        :previousStep="previousStep"
      />
    </ValidationObserver>
  </form>
</template>

<script>
import ActionButtons from "@/components/calculator/ActionButtons.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Step4",
  components: {
    ActionButtons,
    ValidationObserver,
    ValidationProvider
  },
  props: [
    "productsQuantityUnit",
    "sulfitePhosphateProportionsUnit",
    "previousStep",
    "nextStep"
  ],
  data() {
    return {
      conventionalProductsQuantity: this.$store.getters
        .conventionalProductsQuantity,
      sulfitePhosphateProportions: this.$store.getters
        .sulfitePhosphateProportions,
      pumps: this.$store.getters.pumps,
      preparationControlTime: this.$store.getters.preparationControlTime,
      alkalinity: this.$store.getters.alkalinity,
      silica: this.$store.getters.silica
    };
  },
  mounted() {
    this.$refs.conventionalProductsQuantity.focus();
  },
  methods: {
    inputValueChange(event, key) {
      this.$emit(`update:${key}`, event.target.value);
    },
    async submit() {
      const isValid = await this.$refs.step4.validate();
      if (!isValid) {
        return;
      }

      this.$store.commit(
        "setConventionalProductsQuantity",
        this.conventionalProductsQuantity
      );
      this.$store.commit(
        "setSulfitePhosphateProportions",
        this.sulfitePhosphateProportions
      );
      this.$store.commit("setPumps", this.pumps);
      this.$store.commit(
        "setPreparationControlTime",
        this.preparationControlTime
      );
      this.$store.commit("setAlkalinity", this.alkalinity);
      this.$store.commit("setSilicia", this.silica);
      this.nextStep();
    }
  }
};
</script>

<style lang="scss" scoped>
.is-danger {
  border: 1px solid red !important;
}
</style>
