<template>
  <div
    id="region-selector-modal"
    class="modal-mask pb-3 py-lg-5"
    v-bind:style="[display ? { display: 'flex' } : { display: 'none' }]"
    style="display: none"
  >
    <div class="w-100 my-auto">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="d-flex align-items-center">
              <img src="@/assets/aquafilm-white.svg" class="aquafilm-logo" />
              <LanguageSwitcher :isBlue="true" />
            </div>
          </div>
        </div>
        <div class="row mt-2 mb-2 mt-lg-3 mb-lg-4">
          <div class="col">
            <h5>
              <span
                class="font-weight-bold text-white"
                v-html="$t('modal.title')"
              ></span>
            </h5>
          </div>
        </div>
        <div class="row mb-2 mb-lg-3">
          <div class="col">
            <span
              class="text-white"
              v-html="$t('modal.region-selector')"
            ></span>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-lg-4">
            <div class="d-flex align-items-center form-group">
              <img
                src="@/assets/icons/regions/us.svg"
                class="mr-3"
                width="38"
                height="38"
              />
              <select
                class="custom-select bg-white"
                v-model="selectedRegion.us"
                @change="setRegion('US', selectedRegion.us)"
              >
                <option value="" disabled selected></option>
                <option
                  v-for="(region, index) in sortRegions(
                    Object.entries($t('countries')['US'].regions)
                  )"
                  :key="index"
                  :value="region[0]"
                >
                  {{ region[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex align-items-center form-group">
              <img
                src="@/assets/icons/regions/canada.svg"
                class="mr-3"
                width="38"
                height="38"
              />
              <select
                class="custom-select bg-white"
                v-model="selectedRegion.canada"
                @change="setRegion('CA', selectedRegion.canada)"
              >
                <option value="" disabled selected></option>
                <option
                  v-for="(region, index) in sortRegions(
                    Object.entries($t('countries')['CA'].regions)
                  )"
                  :key="index"
                  :value="region[0]"
                >
                  {{ region[1] }}
                </option>
              </select>
            </div>
          </div>
          <div class="col-lg-4">
            <div class="d-flex align-items-center form-group">
              <img
                src="@/assets/icons/regions/world.svg"
                class="mr-3"
                width="38"
                height="38"
              />
              <button
                class="btn btn-others text-center w-100"
                @click="setRegion('other', null)"
                v-bind:class="[
                  isOthersButtonPressed ? 'btn-others-pressed' : ''
                ]"
              >
                <span v-html="$t('modal.others')"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cookies my-3 my-lg-5"
        v-bind:style="[
          $store.getters.areCookiesEnabled === 'true' ||
          $store.getters.areCookiesEnabled === true
            ? { display: 'none' }
            : { display: 'block' }
        ]"
      >
        <div class="container">
          <div class="row align-items-center py-2 py-lg-4">
            <div class="col-lg-8">
              <span
                class="cookies-title"
                v-html="$t('modal.cookies.title')"
              ></span>
            </div>
            <div class="col-lg-4 mt-3 mt-lg-0">
              <CustomRadioButton
                name="areCookiesEnabled"
                :value="true"
                i18nKey="modal.cookies.yes"
                :action="setCookiesAreEnabled"
                :checked="true"
              />
              <CustomRadioButton
                name="areCookiesEnabled"
                :value="false"
                i18nKey="modal.cookies.no"
                :action="setCookiesAreEnabled"
                :checked="false"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-3 mt-lg-5">
        <div class="row">
          <div class="col-lg-4 offset-lg-8">
            <button
              class="btn btn-info text-center w-100"
              @click="submit"
              :disabled="country === null"
            >
              <span v-html="$t('modal.confirm')"></span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomRadioButton from "@/components/common/CustomRadioButton.vue";
import LanguageSwitcher from "@/components/common/LanguageSwitcher.vue";
import DistributorMixin from "@/mixins/DistributorMixin.js";

export default {
  name: "RegionSelectorModal",
  mixins: [DistributorMixin],
  components: {
    CustomRadioButton,
    LanguageSwitcher
  },
  data() {
    return {
      display: false,
      country: null,
      region: null,
      areCookiesEnabled: true,
      isOthersButtonPressed: false,
      selectedRegion: {
        canada: "",
        us: ""
      }
    };
  },
  mounted() {
    const country = this.$store.getters.country;
    const region = this.$store.getters.region;
    const areCookiesEnabled = this.$store.getters.areCookiesEnabled;
    this.display = !areCookiesEnabled && this.$route.hash === "";
    this.setRegion(country, region);

    // Listeners:
    this.$root.$on("openRegionSelectorModal", open => {
      this.display = open;
    });

    document.querySelector("body").style.overflowY = "auto";
  },
  watch: {
    display(isDisplayed) {
      console.log(isDisplayed);
      if (isDisplayed) {
        document.querySelector("body").style.overflowY = "hidden";
      } else {
        document.querySelector("body").style.overflowY = "auto";
      }
    }
  },
  methods: {
    sortRegions(regions) {
      return regions.sort((a, b) => {
        return a[1].toUpperCase().localeCompare(b[1].toUpperCase());
      });
    },
    setRegion(country, region) {
      this.country = country;
      this.region = region;

      if (country === "US") {
        this.selectedRegion = { canada: "", us: region };
        this.isOthersButtonPressed = false;
      } else if (country === "CA") {
        this.selectedRegion = { canada: region, us: "" };
        this.isOthersButtonPressed = false;
      } else if (country === "other" && !this.isOthersButtonPressed) {
        this.selectedRegion = { canada: "", us: "" };
        this.isOthersButtonPressed = true;
      } else {
        this.country = null;
        this.selectedRegion = { canada: "", us: "" };
        this.isOthersButtonPressed = false;
      }
    },
    submit() {
      if (this.areCookiesEnabled) {
        this.setCookies(this.country, this.region);
        this.$cookies.set("areCookiesEnabled", this.areCookiesEnabled, "30d");
      }

      this.$store.commit("setCountry", this.country);
      this.$store.commit("setRegion", this.region);
      this.$store.commit("setCookiesEnabled", this.areCookiesEnabled);

      this.goToDistributorPage(this.country, this.region);
      this.display = false;
    },
    setCookiesAreEnabled(value) {
      this.areCookiesEnabled = value;
    }
  }
};
</script>

<style lang="scss" scoped>
@media (max-width: $large_screen_width) {
  h5 {
    font-size: 1rem !important;
  }

  .cookies-title {
    font-size: 12.8px;
  }
}

.modal-mask {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(78, 78, 80, 0.96);
  font-family: "arial_rounded_mt_stdregular", sans-serif;
  z-index: $modal-zindex;
  align-items: center;
  overflow-y: auto;
}

.aquafilm-logo {
  height: 55px;
}

.cookies {
  width: 100%;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  color: black;
  background-color: white;
}
</style>
