<template>
  <div
    ref="calculator"
    class="py-6"
    :style="[step !== 0 ? { backgroundColor: '#f1f1f1' } : {}]"
  >
    <div class="container">
      <div v-if="step !== 0">
        <div class="row mb-4">
          <div class="col text-center">
            <img src="@/assets/aquafilm.svg" width="200" />
          </div>
        </div>
        <div class="row mb-4">
          <div class="col">
            <div class="title">
              <span v-html="$t('title')"></span>
            </div>
          </div>
        </div>
      </div>

      <!-- Home -->
      <Step0 :nextStep="nextStep" v-if="step === 0" />

      <!-- Steps -->
      <div v-if="step !== 0 && step !== 6">
        <ProgressBar :step="step" :totalSteps="totalSteps - 1"></ProgressBar>
        <Step1
          v-if="step === 1"
          :nextStep="nextStep"
          :previousStep="previousStep"
        />
        <Step2
          v-if="step === 2"
          :capacityUnit.sync="capacityUnit"
          :nextStep="nextStep"
          :previousStep="previousStep"
        />
        <Step3
          v-if="step === 3"
          :gasPriceUnit.sync="gasPriceUnit"
          :waterCostUnit.sync="waterCostUnit"
          :co2CostUnit.sync="co2CostUnit"
          :nextStep="nextStep"
          :previousStep="previousStep"
        />
        <Step4
          v-if="step === 4"
          :productsQuantityUnit.sync="productsQuantityUnit"
          :sulfitePhosphateProportionsUnit.sync="
            sulfitePhosphateProportionsUnit
          "
          :nextStep="nextStep"
          :previousStep="previousStep"
        />
        <Step5
          v-if="step === 5"
          :nextStep="nextStep"
          :previousStep="previousStep"
        />
      </div>

      <Results
        v-if="step === 6"
        :actualConcentrationCycle="getEstimatedTheoreticalPowerCycle()"
        :aquafilmConcentrationCycle="getWeightedAquafilmCycleValue()"
        :actualControlTimePerWeek="$store.getters.preparationControlTime"
        :aquafilmControlTimePerWeek="
          $store.getters.preparationControlTime / 2
        "
        :actualProductsQty="$store.getters.conventionalProductsQuantity"
        :conventionalProductsQuantityUnit="productsQuantityUnit"
        :aquafilmProductsQtyInKg="getAquafilmProductsQty()"
        :energyLostReduction="getGasCostSaving()"
        :energySavings="getTotalEnergySavings()"
        :energySavingUnit="gasPriceUnit"
        :energyTransfertSaving="getEnergyTransferSaving()"
        :co2TonsSaving="getCO2Saving()"
        :co2TonsSavingUnit="co2CostUnit"
        :co2CostSaving="getCO2CostSaving()"
        :waterSavingInCubicMeters="getWaterSavingInCubicMeters()"
        :waterCostSaving="getWaterCostSaving()"
        :waterSavingUnit="waterCostUnit"
        :inventoryCostSaving="getInventoryCostSaving()"
        :preparationControlTimeCostSaving="getReductionOfMonitoringTime()"
        :maintenanceCostSaving="getMaintenanceCostSaving()"
        :previousStep="previousStep"
        :sendResultsToMagnus="sendResultsToMagnus"
        :sendResultsToEmail="sendResultsToEmail"
      />
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/calculator/ProgressBar.vue";
import Results from "@/components/calculator/Results.vue";
import Step0 from "@/components/calculator/steps/Step0.vue";
import Step1 from "@/components/calculator/steps/Step1.vue";
import Step2 from "@/components/calculator/steps/Step2.vue";
import Step3 from "@/components/calculator/steps/Step3.vue";
import Step4 from "@/components/calculator/steps/Step4.vue";
import Step5 from "@/components/calculator/steps/Step5.vue";
import * as constants from "@/constants.js";
import Converter from "@/mixins/Converter.js";
import SendCalculatorResults from "@/mixins/SendCalculatorResults.js";
import ResizeMixin from "@/mixins/ResizeMixin.js";
import convert from "convert-units";

export default {
  name: "Calculator",
  components: {
    ProgressBar,
    Results,
    Step0,
    Step1,
    Step2,
    Step3,
    Step4,
    Step5
  },
  mixins: [Converter, ResizeMixin, SendCalculatorResults],
  data() {
    return {
      step: 0,
      totalSteps: 6,
      capacityUnit:
        localStorage.getItem("capacityUnit") || constants.HORSE_POWER,
      gasPriceUnit:
        localStorage.getItem("gasPriceUnit") ||
        constants.DOLLARS_PER_CUBIC_METER,
      waterCostUnit:
        localStorage.getItem("waterCostUnit") ||
        constants.DOLLARS_PER_CUBIC_METER,
      co2CostUnit:
        localStorage.getItem("co2CostUnit") || constants.DOLLARS_PER_TON,
      productsQuantityUnit:
        localStorage.getItem("productsQuantityUnit") ||
        constants.KILOGRAM_BY_YEAR,
      sulfitePhosphateProportionsUnit:
        localStorage.getItem("sulfitePhosphateProportionsUnit") ||
        constants.KILOGRAM_BY_YEAR
    };
  },
  methods: {
    getReductionOfMonitoringTime() {
      const operatorWage = parseFloat(this.$store.getters.operatorWage);
      const preparationControlTime = parseFloat(this.$store.getters.preparationControlTime);
      const operationDays = parseFloat(this.$store.getters.operationDays);

      return preparationControlTime * (operationDays / 7) * 0.25 * operatorWage;
    },
    getTotalEnergySavings() {
      return this.getEnergyTransferGain() + this.getGasSaving();
    },
    getMakeupWater() {
      const _makeupWater = this.$store.getters.makeupWater;
      return _makeupWater / 100;
    },

    // Évaluation: cellule B13
    getAnnualSteamProduction() {
      const averageCapacity =
        parseFloat(this.$store.getters.averageCapacity) / 100;
      const capacity = parseFloat(this.$store.getters.capacity);
      const operationDays = parseInt(this.$store.getters.operationDays);
      const operationHours = parseInt(this.$store.getters.operationHours);
      const hpCapacity = this.getHorsePower(capacity, this.capacityUnit);
      return hpCapacity * operationDays * operationHours * averageCapacity;
    },
    // Évaluation: cellule B14
    getGasTheoreticalConsumption() {
      const efficiency = parseFloat(this.$store.getters.efficiency) / 100;
      const steamProduction = this.getAnnualSteamProduction();
      return (
        (steamProduction * constants.CUBIC_FEET_TO_CUBIC_METER) / efficiency
      );
    },
    // Évaluation: cellule B37
    getEstimatedTheoreticalPowerCycle() {
      const conductivity = this.$store.getters.conductivity;
      const maintainedConductivity = parseFloat(
        this.$store.getters.maintainedConductivity
      );
      const steamProduction = this.getAnnualSteamProduction();
      const makeupWater = this.getMakeupWater();
      const metricProportions = this.getKgPerYear(
        parseFloat(this.$store.getters.sulfitePhosphateProportions),
        this.sulfitePhosphateProportionsUnit
      );
      return (
        (maintainedConductivity - 1000) /
        (conductivity * makeupWater +
          (metricProportions * 1000000) / (steamProduction / 2.2))
      );
    },
    // Évaluation: cellule E36
    getActualCycle() {
      const steamProduction = this.getAnnualSteamProduction();
      const powerCycle = this.getEstimatedTheoreticalPowerCycle();
      return steamProduction / (1 - 1 / powerCycle) - steamProduction;
    },
    // Évaluation: cellule E38
    getAquafilmCycle() {
      const steamProduction = this.getAnnualSteamProduction();
      const weightedAquafilmCycleValue = this.getWeightedAquafilmCycleValue();
      return (
        steamProduction / (1 - 1 / weightedAquafilmCycleValue) - steamProduction
      );
    },
    // Évaluation: cellule B39
    getMaxAlkalinityCycle() {
      const alkalinity = parseFloat(this.$store.getters.alkalinity);
      const makeupWater = this.getMakeupWater();
      return constants.MAX_BOILER_ALKALINITY / (alkalinity * makeupWater);
    },
    // Évaluation: cellule B40
    getMaxSilicaCycle() {
      const silica = parseFloat(this.$store.getters.silica);
      const makeupWater = this.getMakeupWater();
      return constants.MAX_BOILER_SILICA / (silica * makeupWater);
    },
    // Évaluation: cellule B41
    getTheoreticalCyclePerTDS() {
      const alkalinity = parseFloat(this.$store.getters.alkalinity);
      const conductivity = parseFloat(this.$store.getters.conductivity);
      const makeupWater = this.getMakeupWater();
      return (
        constants.AQUAFILM_BOILER_CONDUCTIVITY /
        ((conductivity + alkalinity) * makeupWater)
      );
    },
    // Évaluation: cellule B42
    getWeightedAquafilmCycleValue() {
      const alkalinityCycle = this.getMaxAlkalinityCycle();
      const silicaCycle = this.getMaxSilicaCycle();
      const theoreticalCyclePerTds = this.getTheoreticalCyclePerTDS();
      const aquafilmCycleValue = Math.min(
        alkalinityCycle,
        silicaCycle,
        theoreticalCyclePerTds
      );

      if (aquafilmCycleValue > constants.MAX_AQUAFILM_CYCLE_VALUE) {
        return constants.MAX_AQUAFILM_CYCLE_VALUE;
      }

      return aquafilmCycleValue;
    },

    // ### GAS ###
    // Évaluation: cellule B49
    getActualGasPerPurge() {
      const a = this.getActualCycle();
      return (
        ((a * constants.LBS_TO_BTU) / constants.CUBIC_FEET_TO_BTU) *
        constants.CUBIC_FEET_TO_CUBIC_METER
      );
    },
    // Évaluation: cellulule C49
    getAquafilmGasPerPerge() {
      const a = this.getAquafilmCycle();
      return (
        ((a * constants.LBS_TO_BTU) / constants.CUBIC_FEET_TO_BTU) *
        constants.CUBIC_FEET_TO_CUBIC_METER
      );
    },
    // Évaluation: cellule B50
    getActualAnnualGasCost() {
      const gasPrice = parseFloat(this.$store.getters.gasPrice);
      const gasPerPurginWater = this.getActualGasPerPurge();
      return (
        gasPerPurginWater *
        this.getPricePerCubicMeter(gasPrice, this.gasPriceUnit)
      );
    },
    // Évaluation: cellule C50
    getAquafilmAnnualGasCost() {
      const gasPrice = parseFloat(this.$store.getters.gasPrice);
      const gasPerPurginWater = this.getAquafilmGasPerPerge();
      return (
        gasPerPurginWater *
        this.getPricePerCubicMeter(gasPrice, this.gasPriceUnit)
      );
    },
    // Évaluation: cellule C51
    getGasSaving() {
      const actual = this.getActualGasPerPurge();
      const aquafilm = this.getAquafilmGasPerPerge();
      return actual - aquafilm;
    },
    // Évaluation: cellule C52
    getGasCostSaving() {
      const actual = this.getActualAnnualGasCost();
      const aquafilm = this.getAquafilmAnnualGasCost();
      return actual - aquafilm;
    },
    // Évaluation: cellule C53
    getEnergyTransferGain() {
      const consumption = this.getGasTheoreticalConsumption();
      return consumption * constants.ENERGY_TRANSFER_GAIN;
    },
    // Évaluation: cellule C54
    getEnergyTransferSaving() {
      const gasPrice = parseFloat(this.$store.getters.gasPrice);
      const gain = this.getEnergyTransferGain();
      const price = this.getPricePerCubicMeter(gasPrice, this.gasPriceUnit);
      return gain * price;
    },

    // ### CO2 ###
    // Évaluation: cellule B55
    getActualCO2Production() {
      const gas = this.getActualGasPerPurge();
      const gain = this.getEnergyTransferGain();

      return ((gas + gain) * constants.CUBIC_METER_OF_GAS_TO_CO2) / 1000;
    },
    // Évaluation: cellule C55
    getAquafilmCO2Production() {
      const gas = this.getAquafilmGasPerPerge();
      return (gas * constants.CUBIC_METER_OF_GAS_TO_CO2) / 1000;
    },
    // Évaluation: cellule C56
    getCO2Saving() {
      const actual = this.getActualCO2Production();
      const aquafilm = this.getAquafilmCO2Production();
      return actual - aquafilm;
    },
    // Évaluation: cellule C57
    getCO2CostSaving() {
      const co2Cost = parseFloat(this.$store.getters.co2Cost);
      const co2CostPerTons = this.getPricePerTons(co2Cost, this.co2CostUnit);
      return this.getCO2Saving() * co2CostPerTons;
    },

    // ### WATER ###
    // Évaluation: cellule B58
    getActualWaterQtyPerPurge() {
      return this.getActualCycle() / 10;
    },
    // Évaluation: cellule C58
    getAquafilmWaterQtyPerPurge() {
      return this.getAquafilmCycle() / 10;
    },
    // Évaluation: cellule C59
    getWaterSavingInGal() {
      const actual = this.getActualWaterQtyPerPurge();
      const aquafilm = this.getAquafilmWaterQtyPerPurge();
      return actual - aquafilm;
    },
    // Évaluation: cellule C60
    getWaterSavingInCubicMeters() {
      return this.getWaterSavingInGal() * 0.004546;
    },
    // Évaluation: cellule C61
    getWaterCostSaving() {
      const waterCost = parseFloat(this.$store.getters.waterCost);
      const price = this.getPricePerCubicMeter(waterCost, this.waterCostUnit);
      const waterSaving = this.getWaterSavingInCubicMeters();
      return waterSaving * price;
    },

    // Évaluation: cellule C62
    getControlTimeSavingPerWeek() {
      const operatorWage = parseFloat(this.$store.getters.operatorWage);
      const preparationControlTime = parseFloat(
        this.$store.getters.preparationControlTime
      );
      return (preparationControlTime / 4) * operatorWage;
    },
    // Évaluation: cellule D62
    getControlTimeSavingPerYear() {
      const controlTimeSaving = this.getControlTimeSavingPerWeek();
      const operationDays = parseInt(this.$store.getters.operationDays);
      return controlTimeSaving * ((52 * operationDays) / 365);
    },

    // ### RESULTS ###
    getAquafilmProductsQty() {
      const makeupWater = this.getMakeupWater();
      const steamProduction = this.getAnnualSteamProduction();
      const weightedAquafilmCycleValue = this.getWeightedAquafilmCycleValue();
      return (
        (constants.REQUIRED_AQUAFILM_DOSAGE /
          (weightedAquafilmCycleValue * makeupWater)) *
        ((steamProduction * makeupWater) / (2.2 * 1000000))
      );
    },
    // Avantages: cellule D16
    getInventoryCostSaving() {
      const productsQuantity = parseFloat(
        this.$store.getters.conventionalProductsQuantity
      );
      const inventoryCost = parseFloat(this.$store.getters.inventoryCost);
      const productsQty = this.getAquafilmProductsQty();
      const kgPerYear = this.getKgPerYear(
        productsQuantity,
        this.productsQuantityUnit
      );
      return inventoryCost - (inventoryCost * productsQty) / kgPerYear;
    },
    // Avantages: cellule D18
    getMaintenanceCostSaving() {
      const maintenanceCost = parseFloat(this.$store.getters.maintenanceCost);
      const pumps = parseInt(this.$store.getters.pumps);
      return maintenanceCost - maintenanceCost / pumps;
    },

    previousStep() {
      if (this.step > 0) {
        this.step -= 1;
        const offsetCss = getComputedStyle(
          document.documentElement
        ).getPropertyValue("--top-offset");
        const offsetValue = parseInt(offsetCss.replace("px", ""));

        window.scrollTo(0, this.$refs.calculator.offsetTop - offsetValue - 50);
      }
    },
    nextStep() {
      if (this.totalSteps !== this.step) {
        this.step += 1;
        this.$store.commit("setStepIndex", this.step);

        const offsetCss = getComputedStyle(
          document.documentElement
        ).getPropertyValue("--top-offset");
        const offsetValue = parseInt(offsetCss.replace("px", ""));

        window.scrollTo(0, this.$refs.calculator.offsetTop - offsetValue - 50);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.py-6 {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;
}
</style>
