<template>
  <div class="row m-0">
    <div class="col p-0 my-4">
      <div v-if="hasPrevious" class="float-left">
        <button
          type="button"
          class="btn btn-info text-uppercase mr-2"
          @click="previousStep"
        >
          <font-awesome-icon class="chevron mr-2" icon="chevron-left" />
          <span v-html="$t('previous')"></span>
        </button>
      </div>
      <div v-if="hasNext" class="float-right">
        <button type="submit" class="btn btn-info text-uppercase">
          <div class="d-flex align-items-center">
            <span v-html="$t('next')"></span>
            <font-awesome-icon class="chevron ml-2" icon="chevron-right" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionButtons",
  props: ["hasNext", "hasPrevious", "previousStep"]
};
</script>
