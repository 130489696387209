<template>
  <div class="top-offset">
    <Header />

    <StickyMenu class="d-none d-lg-block"></StickyMenu>

    <div id="calculator">
      <AquafilmCalculator
        :locale="$i18n.locale"
        :country="$store.getters.country"
        :region="$store.getters.region"
      ></AquafilmCalculator>
    </div>

    <VideoPlayer id="video" />

    <Benefits id="benefits" class="my-6" />

    <StudyCases id="study-cases" class="my-6" />

    <Faq id="faq" class="my-6" />

    <Contact id="expert" class="py-6"></Contact>

    <Map id="map" class="pb-6"></Map>
  </div>
</template>

<script>
// Components
import AquafilmCalculator from "@/components/calculator/AquafilmCalculator.vue";
import Benefits from "@/components/Benefits.vue";
import Contact from "@/components/Contact.vue";
import Faq from "@/components/Faq.vue";
import Header from "@/components/Header.vue";
import Map from "@/components/Map.vue";
import StickyMenu from "@/components/StickyMenu.vue";
import StudyCases from "@/components/StudyCases.vue";
import VideoPlayer from "@/components/VideoPlayer.vue";

export default {
  name: "Home",
  metaInfo() {
    return {
      title: this.$i18n.t("meta.title"),
      meta: [
        { charset: "utf-8" },
        { name: "description", content: this.$i18n.t("meta.description") },
        { name: "keywords", content: this.$i18n.t("meta.keywords") }
      ]
    };
  },
  components: {
    AquafilmCalculator,
    Benefits,
    Contact,
    Header,
    Faq,
    Map,
    StickyMenu,
    StudyCases,
    VideoPlayer
  }
};
</script>

<style lang="scss">
.top-offset {
  padding-top: 115px;
}
</style>
