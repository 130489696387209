<template>
  <div class="container mb-n5">
    <!-- Safe and easy to use -->
    <div class="row align-items-center">
      <div class="col-md-6 order-md-last mb-5">
        <div class="d-flex align-items-center mb-4">
          <img
            class="mr-4"
            src="@/assets/benefits/safe-and-easy/icon.svg"
            height="90"
            width="90"
          />
          <div class="benefit" v-html="$t('benefits[0].title')"></div>
        </div>
        <div
          class="mb-2"
          v-for="(bullet, index) in $t('benefits[0].bullets')"
          :key="index"
        >
          {{ bullet }}
        </div>
      </div>
      <div class="col-md-6 mb-4">
        <div class="image-container">
          <img src="@/assets/benefits/safe-and-easy/image.png" class="w-100" />
          <div class="safe-and-easy-square-1 medium-square"></div>
          <div class="safe-and-easy-square-2 large-square"></div>
          <div class="safe-and-easy-square-3 medium-square"></div>
        </div>
      </div>
    </div>
    <!-- Save energy & water -->
    <div class="row align-items-center mb-4">
      <div class="col-md-6 mb-5">
        <div class="d-flex align-items-center mb-4">
          <img
            class="mr-4"
            src="@/assets/benefits/energy/icon.svg"
            height="90"
            width="90"
          />
          <div class="benefit" v-html="$t('benefits[1].title')"></div>
        </div>
        <div
          class="mb-2"
          v-for="(bullet, index) in $t('benefits[1].bullets')"
          :key="index"
        >
          {{ bullet }}
        </div>
      </div>
      <div class="col-md-6 mb-5">
        <div class="image-container">
          <img src="@/assets/benefits/energy/image.png" class="w-100" />
          <div class="energy-square-1 large-square"></div>
          <div class="energy-square-2 medium-square"></div>
          <div class="energy-square-3 medium-square"></div>
        </div>
      </div>
    </div>
    <!-- Cost effective -->
    <div class="row align-items-center">
      <div class="col-md-6 mb-4 order-md-last">
        <div class="d-flex align-items-center mb-4">
          <img
            class="mr-4"
            src="@/assets/benefits/cost-effective/icon.svg"
            height="90"
            width="90"
          />
          <div class="benefit" v-html="$t('benefits[2].title')"></div>
        </div>
        <div
          class="mb-2"
          v-for="(bullet, index) in $t('benefits[2].bullets')"
          :key="index"
        >
          {{ bullet }}
        </div>
      </div>
      <div class="col-md-6 mb-5">
        <div class="image-container">
          <img
            src="@/assets/benefits/cost-effective/image-fr.png"
            class="w-100"
            v-if="$i18n.locale === 'fr'"
          />
          <img
            src="@/assets/benefits/cost-effective/image-en.png"
            class="w-100"
            v-if="$i18n.locale === 'en'"
          />
          <div class="cost-effective-square-1 medium-square"></div>
          <div class="cost-effective-square-2 medium-square"></div>
          <div class="cost-effective-square-3 large-square"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Benefits"
};
</script>

<style lang="scss" scoped>
.benefit {
  color: $primary;
  font-size: 1.25rem;
  font-weight: bold;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
}

.image-container {
  position: relative;
}

// Safe and easy to use
.safe-and-easy-square-1 {
  top: calc(-1 * #{$medium-square-size} / 2);
  left: calc(#{$medium-square-size} / 2);
  background-color: rgba(61, 73, 117, 0.9);
}

.safe-and-easy-square-2 {
  bottom: 0;
  right: 0;
  background-color: $primary;
}

.safe-and-easy-square-3 {
  bottom: 55px;
  right: 55px;
  background-color: $primary;
  opacity: 0.6;
}

// Save energy & water
.energy-square-1 {
  bottom: calc(-1 * #{$large-square-size} / 2);
  left: 25px;
  background-color: $primary;
  opacity: 0.6;
}

.energy-square-2 {
  top: calc(-1 * #{$medium-square-size} / 2);
  right: calc(#{$medium-square-size} / 2);
  background-color: $primary;
  opacity: 0.6;
}

.energy-square-3 {
  top: 0;
  right: 0;
  background-color: $primary;
  opacity: 0.6;
}

// Cost effective
.cost-effective-square-1 {
  top: 0;
  right: 0;
  background-color: $primary;
  opacity: 0.6;
}

.cost-effective-square-2 {
  bottom: 0;
  right: $medium-square-size;
  background-color: $primary;
  opacity: 0.6;
}

.cost-effective-square-3 {
  bottom: calc(-1 * #{$large-square-size} / 2);
  right: calc(2 * #{$medium-square-size} - 20px);
  background-color: $primary;
  opacity: 0.6;
}
</style>
