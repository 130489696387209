var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"step",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationObserver',{ref:"step3",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"capacity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('gasPrice'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.gasPrice')),expression:"$t('tooltips.gasPrice')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('gasPriceExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.gasPrice),expression:"gasPrice"}],ref:"gasPrice",staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"gasPrice","step":"any"},domProps:{"value":(_vm.gasPrice)},on:{"input":function($event){if($event.target.composing){ return; }_vm.gasPrice=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('select',{staticClass:"custom-select",attrs:{"id":"gasPriceUnit"},domProps:{"value":_vm.gasPriceUnit},on:{"change":function($event){return _vm.inputValueChange($event, 'gasPriceUnit')}}},[_c('option',{attrs:{"value":"dollarsPerCubicMeter"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.dollarsPerCubicMeter'))}}),_c('option',{attrs:{"value":"dollarsPerCubicFeet"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.dollarsPerCubicFeet'))}})])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"waterCost"},domProps:{"innerHTML":_vm._s(_vm.$t('waterCost'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('waterCostExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"validate",rawName:"v-validate"},{name:"model",rawName:"v-model",value:(_vm.waterCost),expression:"waterCost"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"waterCost"},domProps:{"value":(_vm.waterCost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.waterCost=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('select',{staticClass:"custom-select",attrs:{"id":"waterCostUnit"},domProps:{"value":_vm.waterCostUnit},on:{"change":function($event){return _vm.inputValueChange($event, 'waterCostUnit')}}},[_c('option',{attrs:{"value":"dollarsPerCubicMeter"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.dollarsPerCubicMeter'))}}),_c('option',{attrs:{"value":"dollarsPer1000USGallons"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.dollarsPer1000USGallons'))}})])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"operatorWage"},domProps:{"innerHTML":_vm._s(_vm.$t('operatorWage'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('operatorWageExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.operatorWage),expression:"operatorWage"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"operatorWage"},domProps:{"value":(_vm.operatorWage)},on:{"input":function($event){if($event.target.composing){ return; }_vm.operatorWage=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$ / h")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"co2Cost"},domProps:{"innerHTML":_vm._s(_vm.$t('co2Cost'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('co2CostExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.co2Cost),expression:"co2Cost"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"co2Cost"},domProps:{"value":(_vm.co2Cost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.co2Cost=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('select',{staticClass:"custom-select",attrs:{"id":"co2CostUnit"},domProps:{"value":_vm.co2CostUnit},on:{"change":function($event){return _vm.inputValueChange($event, 'co2CostUnit')}}},[_c('option',{attrs:{"value":"dollarsPerTon"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.dollarsPerTon'))}}),_c('option',{attrs:{"value":"dollarsPerShortTonOption"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.dollarsPerShortTonOption'))}})])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('ActionButtons',{attrs:{"hasNext":true,"hasPrevious":true,"previousStep":_vm.previousStep}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }