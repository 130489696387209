var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-mask pb-3 py-lg-5",staticStyle:{"display":"none"},style:([_vm.display ? { display: 'flex' } : { display: 'none' }]),attrs:{"id":"region-selector-modal"}},[_c('div',{staticClass:"w-100 my-auto"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('img',{staticClass:"aquafilm-logo",attrs:{"src":require("@/assets/aquafilm-white.svg")}}),_c('LanguageSwitcher',{attrs:{"isBlue":true}})],1)])]),_c('div',{staticClass:"row mt-2 mb-2 mt-lg-3 mb-lg-4"},[_c('div',{staticClass:"col"},[_c('h5',[_c('span',{staticClass:"font-weight-bold text-white",domProps:{"innerHTML":_vm._s(_vm.$t('modal.title'))}})])])]),_c('div',{staticClass:"row mb-2 mb-lg-3"},[_c('div',{staticClass:"col"},[_c('span',{staticClass:"text-white",domProps:{"innerHTML":_vm._s(_vm.$t('modal.region-selector'))}})])])]),_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"d-flex align-items-center form-group"},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/icons/regions/us.svg"),"width":"38","height":"38"}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRegion.us),expression:"selectedRegion.us"}],staticClass:"custom-select bg-white",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.selectedRegion, "us", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.setRegion('US', _vm.selectedRegion.us)}]}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}}),_vm._l((_vm.sortRegions(
                  Object.entries(_vm.$t('countries')['US'].regions)
                )),function(region,index){return _c('option',{key:index,domProps:{"value":region[0]}},[_vm._v("\n                "+_vm._s(region[1])+"\n              ")])})],2)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"d-flex align-items-center form-group"},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/icons/regions/canada.svg"),"width":"38","height":"38"}}),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.selectedRegion.canada),expression:"selectedRegion.canada"}],staticClass:"custom-select bg-white",on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.selectedRegion, "canada", $event.target.multiple ? $$selectedVal : $$selectedVal[0])},function($event){return _vm.setRegion('CA', _vm.selectedRegion.canada)}]}},[_c('option',{attrs:{"value":"","disabled":"","selected":""}}),_vm._l((_vm.sortRegions(
                  Object.entries(_vm.$t('countries')['CA'].regions)
                )),function(region,index){return _c('option',{key:index,domProps:{"value":region[0]}},[_vm._v("\n                "+_vm._s(region[1])+"\n              ")])})],2)])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"d-flex align-items-center form-group"},[_c('img',{staticClass:"mr-3",attrs:{"src":require("@/assets/icons/regions/world.svg"),"width":"38","height":"38"}}),_c('button',{staticClass:"btn btn-others text-center w-100",class:[
                _vm.isOthersButtonPressed ? 'btn-others-pressed' : ''
              ],on:{"click":function($event){return _vm.setRegion('other', null)}}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('modal.others'))}})])])])])]),_c('div',{staticClass:"cookies my-3 my-lg-5",style:([
        _vm.$store.getters.areCookiesEnabled === 'true' ||
        _vm.$store.getters.areCookiesEnabled === true
          ? { display: 'none' }
          : { display: 'block' }
      ])},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center py-2 py-lg-4"},[_c('div',{staticClass:"col-lg-8"},[_c('span',{staticClass:"cookies-title",domProps:{"innerHTML":_vm._s(_vm.$t('modal.cookies.title'))}})]),_c('div',{staticClass:"col-lg-4 mt-3 mt-lg-0"},[_c('CustomRadioButton',{attrs:{"name":"areCookiesEnabled","value":true,"i18nKey":"modal.cookies.yes","action":_vm.setCookiesAreEnabled,"checked":true}}),_c('CustomRadioButton',{attrs:{"name":"areCookiesEnabled","value":false,"i18nKey":"modal.cookies.no","action":_vm.setCookiesAreEnabled,"checked":false}})],1)])])]),_c('div',{staticClass:"container mt-3 mt-lg-5"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-4 offset-lg-8"},[_c('button',{staticClass:"btn btn-info text-center w-100",attrs:{"disabled":_vm.country === null},on:{"click":_vm.submit}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('modal.confirm'))}})])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }