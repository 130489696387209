const numeral = require("numeral");

numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ","
  },
  currency: {
    symbol: "$"
  }
});

export default numeral;
