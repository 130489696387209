var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"step",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationObserver',{ref:"step5",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"conductivity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('conductivity'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.conductivity')),expression:"$t('tooltips.conductivity')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('conductivityExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: _vm.alkalinity }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.conductivity),expression:"conductivity"}],ref:"conductivity",staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"conductivity"},domProps:{"value":(_vm.conductivity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.conductivity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("μmhos")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"maintainedConductivity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('maintainedConductivity'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.maintainedConductivity')),expression:"$t('tooltips.maintainedConductivity')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('maintainedConductivityExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: _vm.silica }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.maintainedConductivity),expression:"maintainedConductivity"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"maintainedConductivity","step":"any"},domProps:{"value":(_vm.maintainedConductivity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.maintainedConductivity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("μmhos")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"inventoryCost"},domProps:{"innerHTML":_vm._s(_vm.$t('inventoryCost'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('inventoryCostExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.inventoryCost),expression:"inventoryCost"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"inventoryCost"},domProps:{"value":(_vm.inventoryCost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.inventoryCost=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"capacity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('maintenanceCost'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.maintenanceCost')),expression:"$t('tooltips.maintenanceCost')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('maintenanceCostExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.maintenanceCost),expression:"maintenanceCost"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"maintenanceCost"},domProps:{"value":(_vm.maintenanceCost)},on:{"input":function($event){if($event.target.composing){ return; }_vm.maintenanceCost=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("$")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('ActionButtons',{attrs:{"hasNext":true,"hasPrevious":true,"previousStep":_vm.previousStep}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }