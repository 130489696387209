import Vue from "vue";
import Meta from "vue-meta";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import StudyCase from "@/views/StudyCase.vue";

Vue.use(Router);
Vue.use(Meta)

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/:language",
      name: "Home",
      component: Home
    },
    {
      path: "/:language/studyCase/:id",
      name: "StudyCase",
      component: StudyCase
    },
    {
      path: "/:language/:distributor",
      name: "Distributor",
      component: Home
    },
    {
      path: "/:language/:distributor/studyCase/:id",
      name: "DistributorStudyCase",
      component: StudyCase
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash };
    }
    // Do not scroll top when changing the language
    if (to.name !== from.name || to.hash !== from.hash) {
      return { x: 0, y: 0 };
    }
  }
});
