<template>
  <div class="bg-light-grey">
    <div class="container position-relative" ref="contact">
      <div class="row mb-5">
        <div class="col text-center">
          <h3
            class="contact-title text-primary"
            v-html="$t('contact.title')"
          ></h3>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col text-center">
          <h3>
            <a class="phone-anchor" href="tel:18003639929">1 800 363-9929</a>
          </h3>
        </div>
      </div>

      <div class="row mb-4">
        <div class="col text-center">
          <div class="contact-question" v-html="$t('contact.question')"></div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 col-lg-6 offset-lg-3">
          <ValidationObserver ref="observer" v-slot="{ invalid }">
            <form class="contact-form m-auto" @submit.prevent="submit">
              <div class="form-group text-left">
                <ValidationProvider
                  ref="lastName"
                  name="lastName"
                  mode="eager"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="lastName" v-html="$t('contact.lastName')"></label>
                  <input
                    id="lastName"
                    type="text"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="lastName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group text-left">
                <ValidationProvider
                  ref="firstName"
                  name="firstName"
                  mode="eager"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label
                    for="firstName"
                    v-html="$t('contact.firstName')"
                  ></label>
                  <input
                    id="firstName"
                    type="text"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="firstName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group text-left">
                <ValidationProvider
                  ref="email"
                  name="email"
                  mode="eager"
                  rules="required|customEmail"
                  v-slot="{ errors }"
                >
                  <label for="email" v-html="$t('contact.email')"></label>
                  <input
                    id="email"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group text-left mb-4">
                <ValidationProvider
                  ref="phone"
                  name="phone"
                  mode="eager"
                  rules="required|customPhone"
                  v-slot="{ errors }"
                >
                  <label for="phone" v-html="$t('contact.phone')"></label>
                  <vue-tel-input
                    id="phone"
                    type="text"
                    placeholder=""
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="phone"
                    :defaultCountry="getDefaultCountry"
                    :preferredCountries="['ca', 'us', 'es']"
                  ></vue-tel-input>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>

              <div class="form-group text-left">
                <ValidationProvider
                  ref="message"
                  name="message"
                  mode="eager"
                  rules="required"
                  v-slot="{ errors }"
                >
                  <label for="message" v-html="$t('contact.message')"></label>
                  <textarea
                    id="message"
                    class="form-control"
                    v-bind:class="{ 'is-danger': errors[0] }"
                    v-model="message"
                  ></textarea>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </div>
              <div
                class="row alert"
                v-bind:style="[
                  isAlertShown ? { display: 'block' } : { display: 'none' }
                ]"
              >
                <div class="col p-0">
                  <div class="alert alert-success" role="alert">
                    <span v-html="$t('contact.success')"></span>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3">
                  <button
                    type="submit"
                    class="btn btn-lg btn-info w-100"
                    v-html="$t('contact.submit')"
                  ></button>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DistributorMixin from "@/mixins/DistributorMixin.js";

import { HTTP } from "@/utils/axios.js";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Contact",
  mixins: [DistributorMixin],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  data: () => ({
    lastName: "",
    firstName: "",
    email: "",
    phone: "",
    message: "",
    isAlertShown: false
  }),
  computed: {
    getDefaultCountry() {
      return this.$store.getters.country === "CA" ? "ca" : "us";
    }
  },
  mounted() {
    this.$validator.validateAll();
  },
  methods: {
    emptyFields() {
      this.lastName = "";
      this.firstName = "";
      this.email = "";
      this.phone = "";
      this.message = "";
    },
    showAlert() {
      this.isAlertShown = true;
      setTimeout(() => {
        this.isAlertShown = false;
      }, 7000);
    },
    async submit() {
      const self = this;
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      const country = this.$store.getters.country;
      const region = this.$store.getters.region;
      const name = this.getDistributorLabel(country, region);
      const data = {
        lastName: this.lastName,
        firstName: this.firstName,
        email: this.email,
        phone: this.phone,
        message: this.message,
        distributor: {
          name: name,
          country: country,
          region: region
        }
      };
      //try {
      await HTTP.post("contact", {
        contact: data
      });
      this.$refs.observer.reset();

      try {
        window.dataLayer.push({ event: "contactForm" });
      } catch (error) {
        console.log(error);
      }

      self.emptyFields();
      self.showAlert();
      /*}catch (error) {
        console.log(error);
      }*/
    }
  },
  watch: {
    "$i18n.locale"() {
      this.$refs.observer.reset();
    }
  }
};
</script>

<style lang="scss" scoped>
.contact-question {
  font-size: 18px;
  font-family: "arial_rounded_mt_stdregular", sans-serif;
}

#phone {
  background-color: white;
}

#message {
  min-height: 200px;
}

.phone-anchor,
.phone-anchor:hover {
  color: $orange;
}

.is-danger {
  border: 1px solid red !important;
}
</style>
