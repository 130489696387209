<template>
  <div id="step-0">
    <div class="row mb-5 pb-5">
      <div class="col">
        <div class="title text-primary" v-html="$t('step0.title')"></div>
      </div>
    </div>

    <div class="row mb-n4">
      <div class="col-md-4 mb-4 text-center">
        <img src="@/assets/calculator/easy-to-use.svg" width="" />
        <div class="row mt-3">
          <div class="col">
            <div v-html="$t('step0.easyToUse')"></div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <img src="@/assets/calculator/handle-calculations.svg" width="" />
        <div class="row mt-3">
          <div class="col">
            <div v-html="$t('step0.handleCalculations')"></div>
          </div>
        </div>
      </div>
      <div class="col-md-4 mb-4 text-center">
        <img src="@/assets/calculator/send-results.svg" width="" />
        <div class="row mt-3">
          <div class="col">
            <div v-html="$t('step0.sendResults')"></div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-5 pt-5">
      <div class="col text-center">
        <button
          class="btn btn-info btn-lg text-uppercase text-white"
          @click="nextStep"
        >
          <div class="d-flex align-items-center">
            <span v-html="$t('step0.calculate')"></span>
            <font-awesome-icon class="chevron ml-2" icon="chevron-right" />
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Step0",
  props: ["nextStep"]
};
</script>

<style lang="scss" scoped>
#step-0 {
  font-family: "arial_rounded_mt_stdregular", sans-serif;
}

img {
  width: auto;
  height: auto;
  max-width: 120px;
  max-height: 100px;
}
</style>
