var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationObserver',{ref:"step1",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col text-left"},[_c('div',{staticClass:"information",domProps:{"innerHTML":_vm._s(_vm.$t('identification'))}}),_c('div',{staticClass:"font-weight-bold",domProps:{"innerHTML":_vm._s(_vm.$t('description'))}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"name"},domProps:{"innerHTML":_vm._s(_vm.$t('name'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],ref:"name",staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"name","type":"text"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"company"},domProps:{"innerHTML":_vm._s(_vm.$t('cie'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.cie),expression:"cie"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"company","type":"text"},domProps:{"value":(_vm.cie)},on:{"input":function($event){if($event.target.composing){ return; }_vm.cie=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"},domProps:{"innerHTML":_vm._s(_vm.$t('email'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"email","type":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('ValidationProvider',{attrs:{"rules":{ required: true, regex: /^\d{3}\d{3}\d{4}$/ }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"},domProps:{"innerHTML":_vm._s(_vm.$t('phone'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"phone","type":"phone","placeholder":"1234567890"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('ActionButtons',{attrs:{"hasNext":true,"hasPrevious":false,"previousStep":_vm.previousStep}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }