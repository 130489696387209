var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-light-grey"},[_c('div',{ref:"contact",staticClass:"container position-relative"},[_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col text-center"},[_c('h3',{staticClass:"contact-title text-primary",domProps:{"innerHTML":_vm._s(_vm.$t('contact.title'))}})])]),_vm._m(0),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col text-center"},[_c('div',{staticClass:"contact-question",domProps:{"innerHTML":_vm._s(_vm.$t('contact.question'))}})])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12 col-lg-6 offset-lg-3"},[_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{staticClass:"contact-form m-auto",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"form-group text-left"},[_c('ValidationProvider',{ref:"lastName",attrs:{"name":"lastName","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"lastName"},domProps:{"innerHTML":_vm._s(_vm.$t('contact.lastName'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"lastName","type":"text"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group text-left"},[_c('ValidationProvider',{ref:"firstName",attrs:{"name":"firstName","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"firstName"},domProps:{"innerHTML":_vm._s(_vm.$t('contact.firstName'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"firstName","type":"text"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group text-left"},[_c('ValidationProvider',{ref:"email",attrs:{"name":"email","mode":"eager","rules":"required|customEmail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"email"},domProps:{"innerHTML":_vm._s(_vm.$t('contact.email'))}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group text-left mb-4"},[_c('ValidationProvider',{ref:"phone",attrs:{"name":"phone","mode":"eager","rules":"required|customPhone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"phone"},domProps:{"innerHTML":_vm._s(_vm.$t('contact.phone'))}}),_c('vue-tel-input',{class:{ 'is-danger': errors[0] },attrs:{"id":"phone","type":"text","placeholder":"","defaultCountry":_vm.getDefaultCountry,"preferredCountries":['ca', 'us', 'es']},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"form-group text-left"},[_c('ValidationProvider',{ref:"message",attrs:{"name":"message","mode":"eager","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('label',{attrs:{"for":"message"},domProps:{"innerHTML":_vm._s(_vm.$t('contact.message'))}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.message),expression:"message"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"message"},domProps:{"value":(_vm.message)},on:{"input":function($event){if($event.target.composing){ return; }_vm.message=$event.target.value}}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"row alert",style:([
                _vm.isAlertShown ? { display: 'block' } : { display: 'none' }
              ])},[_c('div',{staticClass:"col p-0"},[_c('div',{staticClass:"alert alert-success",attrs:{"role":"alert"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('contact.success'))}})])])]),_c('div',{staticClass:"row mt-5"},[_c('div',{staticClass:"col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3"},[_c('button',{staticClass:"btn btn-lg btn-info w-100",attrs:{"type":"submit"},domProps:{"innerHTML":_vm._s(_vm.$t('contact.submit'))}})])])])]}}])})],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mb-2"},[_c('div',{staticClass:"col text-center"},[_c('h3',[_c('a',{staticClass:"phone-anchor",attrs:{"href":"tel:18003639929"}},[_vm._v("1 800 363-9929")])])])])}]

export { render, staticRenderFns }