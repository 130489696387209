var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('form',{staticClass:"step",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationObserver',{ref:"step4",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"conventionalProductsQuantity"},domProps:{"innerHTML":_vm._s(_vm.$t('conventionalProductsQuantity'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('conventionalProductsQuantityExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.conventionalProductsQuantity),expression:"conventionalProductsQuantity"}],ref:"conventionalProductsQuantity",staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"conventionalProductsQuantity"},domProps:{"value":(_vm.conventionalProductsQuantity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.conventionalProductsQuantity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('select',{staticClass:"custom-select",attrs:{"id":"productsQuantityUnit"},domProps:{"value":_vm.productsQuantityUnit},on:{"change":function($event){return _vm.inputValueChange($event, 'productsQuantityUnit')}}},[_c('option',{attrs:{"value":"kgPerYear"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.kgPerYear'))}}),_c('option',{attrs:{"value":"lbsPerYear"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.lbsPerYear'))}})])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"capacity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('sulfitePhosphateProportions'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.sulfitePhosphateProportions')),expression:"$t('tooltips.sulfitePhosphateProportions')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('sulfitePhosphateProportionsExample'))}}),_c('ValidationProvider',{attrs:{"rules":{
              required: true,
              min_value: 0,
              max_value: _vm.conventionalProductsQuantity
            }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.sulfitePhosphateProportions),expression:"sulfitePhosphateProportions"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"sulfitePhosphateProportions"},domProps:{"value":(_vm.sulfitePhosphateProportions)},on:{"input":function($event){if($event.target.composing){ return; }_vm.sulfitePhosphateProportions=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('select',{staticClass:"custom-select",attrs:{"id":"sulfitePhosphateProportionsUnit"},domProps:{"value":_vm.sulfitePhosphateProportionsUnit},on:{"change":function($event){return _vm.inputValueChange(
                      $event,
                      'sulfitePhosphateProportionsUnit'
                    )}}},[_c('option',{attrs:{"value":"kgPerYear"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.kgPerYear'))}}),_c('option',{attrs:{"value":"lbsPerYear"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.lbsPerYear'))}})])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"pumps"},domProps:{"innerHTML":_vm._s(_vm.$t('pumps'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('pumpsExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.pumps),expression:"pumps"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"pumps","pattern":"\\d*"},domProps:{"value":(_vm.pumps)},on:{"input":function($event){if($event.target.composing){ return; }_vm.pumps=$event.target.value}}})]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"preparationControlTime"},domProps:{"innerHTML":_vm._s(_vm.$t('preparationControlTime'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('preparationControlTimeExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.preparationControlTime),expression:"preparationControlTime"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"preparationControlTime"},domProps:{"value":(_vm.preparationControlTime)},on:{"input":function($event){if($event.target.composing){ return; }_vm.preparationControlTime=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",domProps:{"innerHTML":_vm._s(_vm.$t('unit.hours'))}})])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"alkalinity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('alkalinity'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.alkalinity')),expression:"$t('tooltips.alkalinity')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('alkalinityExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.alkalinity),expression:"alkalinity"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"alkalinity"},domProps:{"value":(_vm.alkalinity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.alkalinity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("ppm")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"silica"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('silica'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.silica')),expression:"$t('tooltips.silica')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('silicaExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0, max_value: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.silica),expression:"silica"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"silica","step":"any"},domProps:{"value":(_vm.silica)},on:{"input":function($event){if($event.target.composing){ return; }_vm.silica=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("ppm")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('ActionButtons',{attrs:{"hasNext":true,"hasPrevious":true,"previousStep":_vm.previousStep}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }