import i18n from "../i18n";
import d from "../distributors.json";

const allLanguages = i18n.availableLocales;
const defaultLanguage = i18n.fallbackLocale;
const allIds = [1, 2];
const distributors = d.map(a => a.name);

function getBrowserLanguage() {
  for (let i = 0; i < allLanguages.length; i += 1) {
    if (navigator.language.includes(allLanguages[i])) {
      return allLanguages[i];
    }
  }
  return null;
}

function setCurrentLocale(language) {
  if (i18n.locale !== language) {
    i18n.locale = language;
    $cookies.set('locale', language, "30d");
  }
}

export default function KeepTrackOfRoutes(router) {
  router.beforeEach((to, from, next) => {
    const { language } = to.params;
    const browserLanguage = getBrowserLanguage();
    const userLanguage = $cookies.get('locale');
    const newLanguage = userLanguage || browserLanguage || defaultLanguage;

    if (to.path === "/" || !allLanguages.includes(language)) {
      return next(newLanguage);
    }

    // Check if the distributor exists
    if (to.name.includes("Distributor") && !distributors.includes(to.params.distributor)) {
      return next("/");
    }

    // Check if the study case Id is in the list
    if (to.path.includes("studyCase") && !allIds.includes(parseInt(to.params.id))) {
      return next("/");
    }

    setCurrentLocale(language);

    return next();
  });
}
