<template>
  <div>

    <h4 class="text-center text-uppercase mb-4" v-html="$t('results.title')"></h4>

    <!-- Energy Savings -->
    <div class="d-flex align-items-center font-weight-bold my-2 py-1 saving">
      <div class="mx-3">
        <span class="text-uppercase" v-html="$t('results.energy')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span>{{ numeral(energySavingValue).value() | numFormat("0,0") }}</span>
        <span>&nbsp;</span>
        <span v-html="$t(`unit.${energySavingUnitKey}`)"></span>
      </div>
    </div>
    <!-- Water Savings -->
    <div class="d-flex align-items-center font-weight-bold my-2 py-1 saving">
      <div class="mx-3">
        <span class="text-uppercase" v-html="$t('results.water')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span>{{ numeral(waterSavingValue).value() | numFormat("0,0") }}</span>
        <span>&nbsp;</span>
        <span v-html="$t(`unit.${waterSavingUnitKey}`)"></span>
      </div>
    </div>
    <!-- CO2 Reduction -->
    <div class="d-flex align-items-center font-weight-bold my-2 py-1 saving">
      <div class="mx-3">
        <span class="text-uppercase" v-html="$t('results.co2')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span>{{ numeral(co2SavingValue).value() | numFormat("0,0") }}</span>
        <span>&nbsp;</span>
        <span v-html="$t(`unit.${co2SavingUnitKey}`)"></span>
      </div>
    </div>

    <!-- Total Savings -->
    <a
      class="d-flex align-items-center font-weight-bold my-2 py-1 total-saving"
      href=""
      @click.prevent="collapse"
      role="button"
      v-b-toggle.collapse-results
    >
      <div class="mx-3">
        <span class="text-uppercase" v-html="$t('results.total')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span v-if="$i18n.locale==='fr'">
          {{ numeral(getTotalSavings()).value() | numFormat("0,0 $") }}
        </span>
        <span v-else>
          ${{ numeral(getTotalSavings()).value() | numFormat("0,0") }}
        </span>
        <font-awesome-icon v-if="isCollapsed" class="chevron ml-4" icon="plus-circle" />
        <font-awesome-icon v-else class="chevron ml-4" icon="times-circle" />
      </div>
    </a>
    <b-collapse id="collapse-results">
      <div class="card">
        <!-- Reduction of energy costs from blowdown -->
        <div class="d-flex mt-2">
          <div class="mx-3">
            <span v-html="$t('results.energyLostReduction')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{ numeral(energyLostReduction).value() | numFormat("0,0 $") }}
            </template>
            <template v-else>
              ${{ numeral(energyLostReduction).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
        <hr />
        <!-- Increase of energy transfer -->
        <div class="d-flex">
          <div class="mx-3">
            <span v-html="$t('results.energyTransferSaving')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{ numeral(energyTransfertSaving).value() | numFormat("0,0 $") }}
            </template>
            <template v-else>
              ${{ numeral(energyTransfertSaving).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
        <hr />
        <!-- GHG reduction -->
        <div class="d-flex">
          <div class="mx-3">
            <span v-html="$t('results.co2CostSaving')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{ numeral(co2CostSaving).value() | numFormat("0,0 $") }}
            </template>
            <template v-else>
              ${{ numeral(co2CostSaving).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
        <hr />
        <!-- Reduction of water consumption -->
        <div class="d-flex">
          <div class="mx-3">
            <span v-html="$t('results.waterCostSaving')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{ numeral(waterCostSaving).value() | numFormat("0,0 $") }}
            </template>
            <template v-else>
              ${{ numeral(waterCostSaving).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
        <hr />
        <!-- Savings in inventory costs, transports and handling -->
        <div class="d-flex">
          <div class="mx-3">
            <span v-html="$t('results.inventoryCostSaving')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{ numeral(inventoryCostSaving).value() | numFormat("0,0 $") }}
            </template>
            <template v-else>
              ${{ numeral(inventoryCostSaving).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
        <hr />
        <!-- Savings in time for controlling and preparation -->
        <div class="d-flex">
          <div class="mx-3">
            <span v-html="$t('results.controlTimePerWeek')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{
                numeral(preparationControlTimeCostSaving).value() | numFormat("0,0 $")
              }}
            </template>
            <template v-else>
              ${{ numeral(preparationControlTimeCostSaving).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
        <hr />
        <!-- Injection systems maintenance savings -->
        <div class="d-flex mb-2">
          <div class="mx-3">
            <span v-html="$t('results.maintenanceCostSaving')"></span>
          </div>
          <div class="ml-auto mr-3 text-nowrap text-right">
            <template v-if="$i18n.locale==='fr'">
              {{ numeral(maintenanceCostSaving).value() | numFormat("0,0 $") }}
            </template>
            <template v-else>
              ${{ numeral(maintenanceCostSaving).value() | numFormat("0,0") }}
            </template>
          </div>
        </div>
      </div>
    </b-collapse>

    <!-- Targeted adjustments -->
    <div class="mt-4">
      <b v-html="$t('results.adjustment')"></b>
    </div>
    <div class="d-flex align-items-center my-2 py-1 details-saving">
      <div class="mx-3">
        <span v-html="$t('results.concentrationCycles')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span>{{ numeral(actualConcentrationCycle).value() | numFormat("0,0") }}</span>
        <span>&nbsp;</span>
        <span v-html="$t('to')"></span>
        <span>&nbsp;</span>
        <span>{{ numeral(aquafilmConcentrationCycle).value() | numFormat("0,0") }}</span>
      </div>
    </div>
    <!--
    <div class="d-flex align-items-center my-2 py-1 details-saving">
      <div class="mx-3">
        <span v-html="$t('results.energyTransferOptimisation')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span>{{ numeral(0.5).value() | numFormat("0,0.0") }}</span>
        <span>&nbsp;%</span>
      </div>
    </div>
    -->
    <div class="d-flex align-items-center my-2 py-1 details-saving">
      <div class="mx-3">
        <span v-html="$t('results.controlTimePerWeek')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span v-if="$i18n.locale === 'fr'">
          {{ numeral(preparationControlTimeCostSaving).value() | numFormat("0,0 $") }}
        </span>
        <span v-else>
          ${{ numeral(preparationControlTimeCostSaving).value() | numFormat("0,0") }}
        </span>
      </div>
    </div>
    <div class="d-flex align-items-center my-2 py-1 details-saving">
      <div class="mx-3">
        <span v-html="$t('results.productsQuantitySaving')"></span>
      </div>
      <div class="ml-auto mr-3 text-nowrap text-right">
        <span>
          {{ numeral(getProductsReduction()).value() | numFormat("0,0") }}&nbsp;
        </span>
        <span v-html="$t(`unit.${conventionalProductsQuantityUnitKey}`)"></span>
      </div>
    </div>
    <div class="row align-items-center my-4 mx-0">
      <div class="col p-0">
        <small class="others" v-html="$t('results.note')"></small>
      </div>
    </div>

    <div class="row mx-0 my-4">
      <div class="col-6 p-0 text-left">
        <button type="button" class="btn btn-info mr-2 text-uppercase" @click="previousStep">
          <font-awesome-icon class="chevron mr-2" icon="chevron-left" />
          <span v-html="$t('previous')"></span>
        </button>
      </div>
      <div class="col-6 p-0 text-right">
        <button type="submit" @click="showModal" class="btn btn-info text-uppercase">
          <span class="others" v-html="$t('results.email')"></span>
          <font-awesome-icon class="chevron ml-2" icon="share-square" />
        </button>
      </div>
    </div>

    <!-- Send Email Modal -->
    <SendEmailModal :modalShown.sync="modalShown" :sendResultsToEmail="sendResults" />

  </div>
</template>

<script>
import * as c from "@/constants.js";
import convert from "convert-units";
import SendEmailModal from "@/components/modals/SendEmailModal.vue";

export default {
  name: "Results",
  props: [
    "actualConcentrationCycle",
    "aquafilmConcentrationCycle",
    "actualControlTimePerWeek",
    "aquafilmControlTimePerWeek",
    "actualProductsQty",
    "aquafilmProductsQtyInKg",
    "conventionalProductsQuantityUnit",
    "energyLostReduction",
    "energySavings",
    "energySavingUnit",
    "energyTransfertSaving",
    "co2TonsSaving",
    "co2TonsSavingUnit",
    "co2CostSaving",
    "waterSavingInCubicMeters",
    "waterCostSaving",
    "waterSavingUnit",
    "inventoryCostSaving",
    "preparationControlTimeCostSaving",
    "maintenanceCostSaving",
    "previousStep",
    "sendResultsToMagnus",
    "sendResultsToEmail"
  ],
  components: {
    SendEmailModal
  },
  async beforeMount() {
    this.setResultsToDisplay();

    try {
      const results = this.getResults();
      await this.sendResultsToMagnus(results);
    } catch (error) {
      console.log(error);
    }
  },
  data() {
    return {
      modalShown: false,
      isCollapsed: true,
      energySavingUnitKey: null,
      waterSavingUnitKey: null,
      co2SavingUnitKey: null,
      conventionalProductsQuantityUnitKey: null,
      energySavingValue: null,
      waterSavingValue: null,
      co2SavingValue: null,
      aquafilmProductsQty: null
    };
  },
  methods: {
    setResultsToDisplay() {
      /* Energy Savings */
      if (this.energySavingUnit === c.DOLLARS_PER_CUBIC_METER) {
        this.energySavingUnitKey = c.CUBIC_METER_ENERGY;
        this.energySavingValue = this.energySavings;
      } else {
        this.energySavingUnitKey = c.CUBIC_FEET_ENERGY;
        this.energySavingValue = this.energySavings * c.CUBIC_METER_TO_CUBIC_FEET;
      }
      /* Water Savings */
      if (this.waterSavingUnit === c.DOLLARS_PER_CUBIC_METER) {
        this.waterSavingUnitKey = c.WATER_CUBIC_METERS;
        this.waterSavingValue = this.waterSavingInCubicMeters;
      } else {
        this.waterSavingUnitKey = c.WATER_GALLONS;
        this.waterSavingValue = this.waterSavingInCubicMeters * c.CUBIC_METER_TO_GAL;
      }
      /* Co2 Savings */
      if (this.co2TonsSavingUnit === c.DOLLARS_PER_TON) {
        this.co2SavingUnitKey = c.CO2_METRIC_TONS;
        this.co2SavingValue = this.co2TonsSaving;
      } else {
        this.co2SavingUnitKey = c.CO2_SHORT_TONS;
        this.co2SavingValue = this.co2TonsSaving * c.TON_TO_SHORT_TON;
      }
      /* Conventional Products Quantity Savings */
      if (this.conventionalProductsQuantityUnit === c.KILOGRAM_BY_YEAR) {
        this.conventionalProductsQuantityUnitKey = c.KILOGRAM;
        this.aquafilmProductsQty = this.aquafilmProductsQtyInKg;
      } else {
        this.conventionalProductsQuantityUnitKey = c.LBS;
        this.aquafilmProductsQty = convert(this.aquafilmProductsQtyInKg)
          .from("kg")
          .to("lb");
      }
    },
    getResults() {
      return {
        energySaving: this.energySavingValue,
        energySavingUnit: this.energySavingUnitKey,
        waterSaving: this.waterSavingValue,
        waterSavingUnit: this.waterSavingUnitKey,
        co2Saving: this.co2SavingValue,
        co2SavingUnit: this.co2SavingUnitKey,
        productsQuantitySaving: this.getProductsReduction(),
        productsQuantitySavingUnit: this.conventionalProductsQuantityUnitKey,
        totalSaving: this.getTotalSavings(),
        controlTimePerWeek: this.preparationControlTimeCostSaving
      };
    },
    collapse() {
      this.isCollapsed = !this.isCollapsed;
    },
    getTotalSavings() {
      return (
        this.energyLostReduction +
        this.energyTransfertSaving +
        this.co2CostSaving +
        this.waterCostSaving +
        this.inventoryCostSaving +
        this.preparationControlTimeCostSaving +
        this.maintenanceCostSaving
      );
    },
    getProductsReduction() {
      return this.actualProductsQty - this.aquafilmProductsQty;
    },
    async sendResults(email, locale) {
      const results = this.getResults();
      return this.sendResultsToEmail(email, locale, results);
    },
    showModal() {
      this.modalShown = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.saving {
  min-height: 30px;
  color: $primary;
  background-color: white;
}

.total-saving {
  min-height: 35px;
  color: white !important;
  background-color: $info;
}

.total-saving:hover {
  text-decoration: none;
  color: white;
}

.details-saving {
  min-height: 30px;
  color: white;
  background-color: $primary;
}

.card {
  color: black;
  background-color: #dbdbdb;
}

hr {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

select {
  width: 120px;
}

@media (max-width: $small_screen_width) {
  .saving,
  .total-saving,
  .details-saving,
  .card > div {
    font-size: 12px !important;
  }
}
</style>
