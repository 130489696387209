<template>
  <div id="app">
    <Navbar />

    <router-view />

    <Footer />

    <!-- Floating Contact Module -->
    <div id="sticky-contact">
      <a
        href="#expert"
        v-smooth-scroll="{ offset: -165 }"
        v-if="!$route.name.includes('StudyCase')"
        v-html="$t('contactStickyLink')"
      >
      </a>
      <router-link :to="{ name: 'Home', hash: '#expert' }" v-else>
        <span class="" v-html="$t('contactStickyLink')"></span>
      </router-link>
    </div>

    <!-- Modals -->
    <RegionSelectorModal />
  </div>
</template>

<script>
import DistributorMixin from "@/mixins/DistributorMixin.js";
import Footer from "@/components/Footer.vue";
import Navbar from "@/components/Navbar.vue";
import RegionSelectorModal from "@/components/modals/RegionSelectorModal.vue";
import ResizeMixin from "@/mixins/ResizeMixin.js";

export default {
  name: "app",
  mixins: [DistributorMixin, ResizeMixin],
  data() {
    return {
      isLoaded: false
    };
  },
  components: {
    Footer,
    Navbar,
    RegionSelectorModal
  },
  created() {
    this.initialize();
    this.isLoaded = true;
  }
};
</script>

<style lang="scss">
@import "./styles/global.scss";

#sticky-contact {
  display: flex;
  align-items: center;
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 50px;
  background-color: $orange;
  z-index: $sticky-contact-zindex;

  a {
    display: flex;
    align-items: center;
    color: white;
    writing-mode: vertical-rl;
    transform: rotate(-180deg);
    margin: auto auto;
    width: 50px;
    padding: 0.5rem 0.5rem;
  }

  a:hover {
    text-decoration: none;
  }

  @media (max-width: $small_screen_width) {
    top: auto;
    bottom: 0;
    transform: none;
    width: 100%;

    a {
      transform: rotate(0);
      width: 100%;
      writing-mode: horizontal-tb;
      justify-content: center;
    }
  }
}
</style>
