<template>
  <div>
    <form class="" @submit.prevent="submit">
      <ValidationObserver ref="step1" v-slot="{ invalid }">
        <div class="row mb-3">
          <div class="col text-left">
            <div class="information" v-html="$t('identification')"></div>
            <div class="font-weight-bold" v-html="$t('description')"></div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="name" v-html="$t('name')"></label>
                <input
                  id="name"
                  ref="name"
                  type="text"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="name"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <label for="company" v-html="$t('cie')"></label>
                <input
                  id="company"
                  type="text"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="cie"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <ValidationProvider rules="required|email" v-slot="{ errors }">
                <label for="email" v-html="$t('email')"></label>
                <input
                  id="email"
                  type="email"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  v-model="email"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="form-group">
              <ValidationProvider
                :rules="{ required: true, regex: /^\d{3}\d{3}\d{4}$/ }"
                v-slot="{ errors }"
              >
                <label for="phone" v-html="$t('phone')"></label>
                <input
                  id="phone"
                  type="phone"
                  class="form-control"
                  v-bind:class="{ 'is-danger': errors[0] }"
                  placeholder="1234567890"
                  v-model="phone"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
            </div>
          </div>
        </div>
        <ActionButtons
          :hasNext="true"
          :hasPrevious="false"
          :previousStep="previousStep"
        />
      </ValidationObserver>
    </form>
  </div>
</template>

<script>
import ActionButtons from "@/components/calculator/ActionButtons.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
  name: "Step1",
  components: {
    ActionButtons,
    ValidationObserver,
    ValidationProvider
  },
  props: ["previousStep", "nextStep"],
  data() {
    return {
      name: this.$store.getters.name,
      cie: this.$store.getters.cie,
      email: this.$store.getters.email,
      phone: this.$store.getters.phone
    };
  },
  mounted() {
    this.$refs.name.focus();
  },
  methods: {
    async submit() {
      const isValid = await this.$refs.step1.validate();
      if (!isValid) {
        return;
      }
      this.$store.commit("setName", this.name);
      this.$store.commit("setCie", this.cie);
      this.$store.commit("setEmail", this.email);
      this.$store.commit("setPhone", this.phone);
      this.nextStep();
    }
  }
};
</script>

<style lang="scss" scoped>
.information {
  font-size: 24px;
  font-weight: bold;
  color: $info;
}

.is-danger {
  border: 1px solid red !important;
}
</style>
