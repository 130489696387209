import Vue from "vue";
import VeeValidate from "vee-validate";

import PhoneNumber from "awesome-phonenumber";

import i18n from "../i18n.js";
import messagesFr from "../locales/fr.js";
import messagesEn from "../locales/en.js";

const customEmailRule = {
  getMessage(field, args) {
    return messages[Validator.locale](field);
  },
  validate(value, args) {
    return /.com\s*$/.test(value) || /.ca\s*$/.test(value);
  }
};

const customPhoneRule = {
  getMessage: field => `${field} is not a valid phone number`,
  validate(value) {
    return new Promise(resolve => {
      let phone = new PhoneNumber(value);
      resolve({ valid: phone.isValid() });
    });
  }
};

VeeValidate.Validator.extend("customPhone", customPhoneRule);

VeeValidate.Validator.extend("customEmail", customEmailRule);

Vue.use(VeeValidate, {
  i18n,
  dictionary: {
    fr: { messages: messagesFr },
    en: { messages: messagesEn }
  }
});

export default VeeValidate;
