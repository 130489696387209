<template>
  <div class="container">

    <!-- Title -->
    <div class="row mb-4">
      <div class="col">
        <h3 v-html="$t('studyCase.title')"></h3>
      </div>
    </div>

    <!-- Study Cases -->
    <div class="row justify-content-center">
      <div class="col-sm-6 col-lg-4" v-for="(studyCase, index) in this.$t('studyCases')" :key="index">
        <div class="study-case-container" v-bind:style="[
          index % 2 !== 0
            ? { backgroundColor: 'rgba(18, 118, 169, 0.9)' }
            : { backgroundColor: 'rgba(34, 154, 167, 0.9)' }
        ]">
          <div class="study-case">
            <div class="title" v-html="studyCase.title"></div>
            <div v-html="studyCase.shortDescription"></div>
            <div class="mt-auto ml-auto" :class="[ index % 2 === 0 ? 'ml-md-auto' : 'mr-md-auto' ]">
              <a href="" @click.prevent="goToStudyCase(index)" class="text-underline text-white">
                <u v-html="$t('studyCase.learnMore')"></u>
                <font-awesome-icon icon="chevron-right" class="ml-2" />
              </a>
            </div>
          </div>
          <div
            :class="[ index % 2 !== 0 ? 'square-1' : 'square-2' ]"
            class="large-square d-none d-md-block"
          >
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "StudyCases",
  methods: {
    goToStudyCase(index) {
      const id = index + 1;
      const name = this.$route.name === 'Distributor' ? 'DistributorStudyCase' : 'StudyCase';
      this.$router.push({
        name: name,
        params: { id: id },
      });
    }
  }
}
</script>

<style lang="scss" scoped>
.study-case-container {
  position: relative;
  width: 100%;
  height: 350px;
  margin-bottom: calc(#{$large-square-size} / 2);
}

.study-case {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 2.5rem 2.5rem;
  display: flex;
  flex-direction: column;
  color: white;

  .title {
    font-size: 1.25rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
}

/**
* Squares
*/
.square-1 {
  bottom: calc(-#{$large-square-size} / 2);
  right: -40px;
  background-color: rgba(18, 118, 169, 0.8);
}

.square-2 {
  bottom: calc(-#{$large-square-size} / 2);
  left: -40px;
  background-color: rgba(34, 154, 167, 0.8);
}
</style>
