<template>
  <div id="header">
    <!-- Video -->
    <div class="video-container">
      <img src="@/assets/preview.jpg" />
      <!--
      <video class="video" autoplay loop muted playsinline v-else>
        <source src="https://dn2ajyykj759.cloudfront.net/preview-v3.mp4" type="video/mp4" />
      </video>
      -->
      <div class="title">
        <div class="container">
          <h1 class="text-uppercase text-white text-center">
            <span v-html="$t('h1')"></span>
          </h1>
        </div>
      </div>
      <a
        href="#calculator"
        class="btn btn-primary btn-scroll-down"
        v-smooth-scroll="{ offset: -165 }"
      >
        <font-awesome-icon class="tetx-white" icon="chevron-down" />
      </a>

      <!-- Squares -->
      <div class="square-1 large-square d-none d-lg-block"></div>
      <div class="square-2 medium-square d-none d-lg-block"></div>
      <div class="square-3 medium-square d-none d-lg-block"></div>
      <div class="square-4 large-square d-none d-lg-block"></div>
      <div class="square-5 medium-square d-none d-lg-block"></div>
      <div class="square-6 medium-square d-none d-lg-block"></div>
      <div class="square-7 large-square d-none d-lg-block"></div>
      <div class="square-8 medium-square"></div>
    </div>

    <!-- Introduction -->
    <div class="bg-primary">
      <div class="container">
        <div class="text-white p-5">
          <div class="row mb-2">
            <div class="col text-left">
              <div v-html="$t('header.introduction')"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    isMobile() {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );
    }
  }
};
</script>

<style lang="scss" scoped>
#header {
  font-family: "arial_rounded_mt_stdregular", sans-serif;
}

.video-container {
  position: relative;
  top: 0;
  left: 0;
  padding-top: 56.25%; /* 16:9 Aspect Ratio */

  img, .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.btn-scroll-down {
  position: absolute;
  color: white;
  top: 75%;
  left: 50%;
  transform: translateX(-50%);
  width: 60px;
  height: 60px;

  @media (max-width: $small_screen_width) {
    top: auto;
    width: 50px;
    height: 50px;
    bottom: 5px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (max-width: $small_screen_width) {
    top: 25px;
    transform: translateX(-50%);
  }
}

/**
* Squares
*/
.square-1 {
  top: 0;
  left: 0;
  background-color: $primary;
  opacity: 0.8;
}

.square-2 {
  top: calc(#{$large-square-size} / 2);
  left: calc(#{$large-square-size} / 2);
  background-color: $primary;
  opacity: 0.5;
}

.square-3 {
  top: 150px;
  left: 75px;
  background-color: $primary;
  opacity: 0.8;
}

.square-4 {
  top: 95px;
  left: 175px;
  background-color: $primary;
  opacity: 0.8;
}

.square-5 {
  top: 300px;
  left: 0;
  background-color: $primary;
  opacity: 0.6;
}

.square-6 {
  bottom: $large-square-size;
  right: 20px;
  background-color: $primary;
  opacity: 0.6;
}

.square-7 {
  bottom: 0;
  right: calc(#{$medium-square-size} + 20px);
  background-color: $primary;
  opacity: 0.8;
}

.square-8 {
  bottom: calc(-1 * #{$medium-square-size} / 2);
  right: calc((#{$medium-square-size} / 2) + 20px);
  background-color: rgba(98, 115, 167, 0.6);
}
</style>
