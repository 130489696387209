<template>
  <div>
    <a href="" class="question text-primary" v-b-toggle="'collapse' + index">
      <div class="d-flex align-items-center">
        <button class="btn btn-link" type="button">
          <font-awesome-icon
            class="plus-square text-primary"
            :icon="['far', 'plus-square']"
          />
          <font-awesome-icon
            class="minus-square text-primary"
            :icon="['far', 'minus-square']"
          />
        </button>
        <div v-html="$t(`questions[${index}].question`)"></div>
      </div>
    </a>
    <!-- Mobile -->
    <b-collapse
      :id="'collapse' + index"
      class="answer"
      v-if="windowWidth > 575.98"
      visible
    >
      <div v-html="$t(`questions[${index}].answer`)"></div>
    </b-collapse>
    <!-- Desktop -->
    <b-collapse :id="'collapse' + index" class="answer" v-else>
      <div v-html="$t(`questions[${index}].answer`)"></div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  name: "Question",
  props: ["index"],
  data() {
    return {
      windowWidth: window.innerWidth
    };
  }
};
</script>

<style lang="scss" scoped>
.question {
  font-family: "arial_rounded_mt_stdregular", sans-serif;
  font-weight: bold;
}

.question.collapsed .minus-square {
  display: none;
}

.question:not(.collapsed) .plus-square {
  display: none;
}

.question:hover {
  text-decoration: none;
}

.answer {
  margin-left: 40px;
}
</style>
