var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('form',{staticClass:"step",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('ValidationObserver',{ref:"step2",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"capacity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('capacity'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.capacity')),expression:"$t('tooltips.capacity')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('capacityExample'))}}),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.capacity),expression:"capacity"}],ref:"capacity",staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"capacity"},domProps:{"value":(_vm.capacity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.capacity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('select',{staticClass:"custom-select",attrs:{"id":"capacityUnit"},domProps:{"value":_vm.capacityUnit},on:{"input":function($event){return _vm.inputValueChange($event, 'capacityUnit')}}},[_c('option',{attrs:{"value":"horsePower"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.horsePower'))}}),_c('option',{attrs:{"value":"steamPower"},domProps:{"innerHTML":_vm._s(_vm.$t('unit.steamPower'))}})])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"operationDays"},domProps:{"innerHTML":_vm._s(_vm.$t('operationDays'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('operationDaysExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ min_value: 0, max_value: 365, required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.operationDays),expression:"operationDays"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"operationDays","step":"1"},domProps:{"value":(_vm.operationDays)},on:{"input":function($event){if($event.target.composing){ return; }_vm.operationDays=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",domProps:{"innerHTML":_vm._s(_vm.$t('unit.days'))}})])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"operationHours"},domProps:{"innerHTML":_vm._s(_vm.$t('operationHours'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('operationHoursExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0, max_value: 24 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.operationHours),expression:"operationHours"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"operationHours","step":"1"},domProps:{"value":(_vm.operationHours)},on:{"input":function($event){if($event.target.composing){ return; }_vm.operationHours=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text",domProps:{"innerHTML":_vm._s(_vm.$t('unit.hours'))}})])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"capacity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('makeupWater'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.makeupWater')),expression:"$t('tooltips.makeupWater')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('makeupWaterExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0, max_value: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.makeupWater),expression:"makeupWater"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"makeupWater"},domProps:{"value":(_vm.makeupWater)},on:{"input":function($event){if($event.target.composing){ return; }_vm.makeupWater=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('div',{staticClass:"d-inline-flex align-items-center"},[_c('label',{attrs:{"for":"capacity"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('efficiency'))}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$t('tooltips.efficiency')),expression:"$t('tooltips.efficiency')"}],staticClass:"tooltip-info ml-2"},[_c('font-awesome-icon',{attrs:{"icon":"info"}})],1)])]),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('efficiencyExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0, max_value: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.efficiency),expression:"efficiency"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"efficiency"},domProps:{"value":(_vm.efficiency)},on:{"input":function($event){if($event.target.composing){ return; }_vm.efficiency=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)]),_c('div',{staticClass:"col-12 col-md-6"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"averageCapacity"},domProps:{"innerHTML":_vm._s(_vm.$t('averageCapacity'))}}),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('averageCapacityExample'))}}),_c('ValidationProvider',{attrs:{"rules":{ required: true, min_value: 0, max_value: 100 }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input-group mt-3"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.averageCapacity),expression:"averageCapacity"}],staticClass:"form-control",class:{ 'is-danger': errors[0] },attrs:{"id":"averageCapacity"},domProps:{"value":(_vm.averageCapacity)},on:{"input":function($event){if($event.target.composing){ return; }_vm.averageCapacity=$event.target.value}}}),_c('div',{staticClass:"input-group-append"},[_c('span',{staticClass:"input-group-text"},[_vm._v("%")])])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)])]),_c('ActionButtons',{attrs:{"hasNext":true,"hasPrevious":true,"previousStep":_vm.previousStep}})]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }