/* eslint-disable */

const messages = {
  _default: field => `Le champ n'est pas valide.`,
  after: (field, [target]) => `Le champ doit être postérieur à ${target}.`,
  alpha: field => `Le champ ne peut contenir que des lettres.`,
  alpha_dash: field =>
    `Le champ ne peut contenir que des caractères alpha-numériques, tirets ou soulignés.`,
  alpha_num: field => `Le champ ne peut contenir que des caractères alpha-numériques.`,
  alpha_spaces: field => `Le champ ne peut contenir que des lettres ou des espaces.`,
  before: (field, [target]) => `Le champ doit être antérieur à ${target}.`,
  between: (field, [min, max]) => `Le champ doit être compris entre ${min} et ${max}.`,
  confirmed: (field, [confirmedField]) => `Le champ ne correspond pas à ${confirmedField}.`,
  credit_card: field => `Le champ est invalide.`,
  date_between: (field, [min, max]) => `Le champ doit être situé entre ${min} et ${max}.`,
  date_format: (field, [format]) => `Le champ doit être au format ${format}.`,
  decimal: (field, [decimals = "*"] = []) =>
    `Le champ doit être un nombre et peut contenir ${
      decimals === "*" ? "des" : decimals
    } décimales.`,
  digits: (field, [length]) => `Le champ doit être un nombre entier de ${length} chiffres.`,
  dimensions: (field, [width, height]) =>
    `Le champ doit avoir une taille de ${width} pixels par ${height} pixels.`,
  email: field => `Le champ doit être une adresse e-mail valide.`,
  customEmail: field => `Le champ doit être une adresse e-mail .com ou .ca.`,
  excluded: field => `Le champ doit être une valeur valide.`,
  ext: field => `Le champ doit être un fichier valide.`,
  image: field => `Le champ doit être une image.`,
  included: field => `Le champ doit être une valeur valide.`,
  integer: field => `Le champ doit être un entier.`,
  ip: field => `Le champ doit être une adresse IP.`,
  length: (field, [length, max]) => {
    if (max) {
      return `Le champ doit contenir entre ${length} et ${max} caractères.`;
    }

    return `Le champ doit contenir ${length} caractères.`;
  },
  max: (field, [length]) => `Le champ ne peut pas contenir plus de ${length} caractères.`,
  max_value: (field, [max]) => `Le champ doit avoir une valeur de ${max} ou moins.`,
  mimes: field => `Le champ doit avoir un type MIME valide.`,
  min: (field, [length]) => `Le champ doit contenir au minimum ${length} caractères.`,
  min_value: (field, [min]) => `Le champ doit avoir une valeur de ${min} ou plus.`,
  numeric: field => `Le champ ne peut contenir que des chiffres.`,
  regex: field => `Le champ est invalide.`,
  required: field => `Le champ est obligatoire.`,
  required_if: (field, [target]) =>
    `Le champ est obligatoire lorsque ${target} possède cette valeur.`,
  size: (field, [size]) => `Le champ doit avoir un poids inférieur à ${size}.`,
  url: field => `Le champ n'est pas une URL valide.`
};

export default messages;
